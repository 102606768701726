import { useRef, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Btn } from '../..';
import useMediaQuery from '../../../Hooks/useMediaQuery';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { HomeBannerPouches } from '../../../assets/images';
import styles from './HeaderBanner.module.scss';

gsap.registerPlugin(ScrollTrigger);

const HeaderBanner = () => {
    const breakpoint = useMediaQuery('md');
    const homePageHeaderImg = useRef(null);

    useEffect(() => {
        homePageHeaderImg.current &&
            gsap.to(homePageHeaderImg.current, {
                scale: 1,
                y: 0,
                duration: 1,
                ease: 'power4',
                scrollTrigger: {
                    trigger: homePageHeaderImg.current,
                    toggleActions: 'play reset play pause',
                },
            });
    });

    return (
        <>
            {(
                <div id="alx-background-arroz"
                    className={`${styles.homePageHeader} position-relative pt-5`}
                >
                <div id="alx-background-arroz-int">
                <div id="alx-mitad"></div>

                <div id="alx-banner-flecha-izq"></div>
                <div id="alx-banner-flecha-der"></div>
                <div id="alx-banner-letras"></div>
                <div id="alx-banner-letras-venta"></div>
                <div id="alx-banner-producto"></div>
                <div id="alx-btn-nuevo-banner"></div>


                    <div id="alx-boton-usa">
                        <a href='https://isadorabeans.com/' id='version-ingles-header' target="_blank">VISITA ISADORA EN EU </a>
                    </div>
                </div>
                </div>
            )}
        </>
    );
};

export default HeaderBanner;



/*
return (
        <>
            {breakpoint && (
                <div
                    className={`${styles.homePageHeader} position-relative pt-5`}
                >
                    <Container fluid='lg'>
                        <a href='https://isadorabeans.com/' id='version-ingles-header' target="_blank">VISITA ISADORA EN EU </a>
                        
                        <Row>
                            <Col className='d-flex flex-column justify-content-center'>
                                <h5 className='fw-bold'>
                                    ¿CÓMO USARÁS ISADORA HOY?
                                </h5>
                                <p>
                                    ¿Prefieres una rica sopa o unos típicos
                                    antojitos? Con frijoles Isadora tienes
                                    muchísimas opciones para consentir a tu
                                    familia cada día.
                                </p>
                                <Btn text='Ir a recetas' link='recetas' />
                            </Col>
                            <Col>
                                <div
                                    className={`${styles.homePageHeaderBackground} mx-auto text-center overflow-hidden`}
                                >
                                    <img
                                        alt='Frijoles Isadora'
                                        src={HomeBannerPouches}
                                        className={styles.homePageHeaderImg}
                                        ref={homePageHeaderImg}
                                    />
                                </div>
                            </Col>
                        </Row>


                        
                    </Container>
                </div>
            )}
        </>
    );
*/
