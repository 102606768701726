import { useReducer } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '../../Hooks/useMediaQuery';
import data from './data';
import { NavBarToggle } from '../';
import NavegacionProducto from './NavegacionProducto';
import styles from './NavegacionProductos.module.scss';

const NavegacionProductos = () => {
    //Constantes
    const breakpoint = useMediaQuery('lg');
    const {
        NavProductos,
        NavProductosOverrideClass,
        NavProductosBrand,
        NavProductosList,
    } = styles;
    const { pathname } = useLocation();
    const reducer = (state, action) => {
        switch (action.type) {
            case 'ToggleActive':
                return { ...state, active: !state.active };
            case 'setFocus':
                return { ...state, focus: action.path };
            default:
                return state;
        }
    };
    const [navProperties, dispatch] = useReducer(reducer, {
        active: false,
        focus: '',
    });
    const eventHandler = path => {
        pathname !== path && breakpoint && dispatch({ type: 'setFocus', path });
    };

    /* JSX */

    return (
        <Navbar
            expand='lg'
            className={`${NavProductos} ${NavProductosOverrideClass} position-relative d-flex w-100`}
        >
            <Container fluid className='p-0 px-lg-3'>
                <Navbar.Brand
                    className={`${NavProductosBrand} text-center flex-grow-1 text-white fw-bold text-uppercase d-lg-none m-0`}
                >
                    Productos
                </Navbar.Brand>
                <NavBarToggle
                    active={navProperties.active}
                    eventHandler={dispatch.bind(null, { type: 'ToggleActive' })}
                    type='productos'
                />
                <Navbar.Collapse
                    id='basic-navbar-nav'
                    className='position-static p-0'
                >
                    <Nav className='mx-auto'>
                        <ul
                            className={`${NavProductosList} list-unstyled mb-0 d-lg-flex justify-content-between`}
                        >
                            {data.map((item, index) => (
                                <NavegacionProducto
                                    key={index}
                                    producto={item}
                                    breakpoint={breakpoint}
                                    eventHandler={eventHandler}
                                    navProperties={navProperties}
                                />
                            ))}
                        </ul>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavegacionProductos;
