import React from 'react';
import './style.css';

import FilterNews from '../../components/FilterNews';
import NewsGrid from '../../components/NewsGrid';

import {
    BannerNews
} from '../../assets/images';

class News extends React.Component {
  render() {
    return (
      <>
       <section id='News' className='alx-back-seccion'>
            <div className='container'>
                <div className='row'>

                    <div className="col-xs-12 col-md-12 col-lg-9" id="alx-div-banner-home-noticias">
                        <div className="alx-banner">
                           <img
                              src={BannerNews}
                              alt="News Isadora México"
                              className='alx-img-full'
                          />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-3">
                        <FilterNews />
                    </div>
                    
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="col-xs-12 col-md-12 col-lg-9">
                        <NewsGrid />
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-3">
                    </div>
                </div>
            </div>

        </section>
      </>
    )
  }
}

export default News;