import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Preloader from '../../components/Preloader';
import './styles.scss';

const NewsGrid = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const { pathname } = useLocation();

  const BASE_URL = process.env.REACT_APP_API_BASE_URL_NEWS;
  const endpoint = '/api/news/9';

  const getNews = async () => {
    setLoading(true);

    try {
      const res = await fetch(BASE_URL + endpoint, {
        method: 'GET',
      });

      const data = await res.json();
      setData(data);

      if (data.news) {
        setLinks(data.news.links || []);
      }
     
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNews();
  }, [pathname]);

  let paginacionHTML = null;
  let cantBotonesPag = 0;


  if (!loading && data && data.news && data.news.data.length > 0) {
  

    const currentDomain = window.location.origin;

    const paginacionItems = links
      .filter((link) => link.url !== null)
      .map((link, i) => {
        let urlString = link.url;
        let pageValue = 1;
        cantBotonesPag++;

        let regex = /[?&]page=(\d+)/;
        let match = urlString.match(regex);

        if (match) {
          pageValue = match[1];
        }


        let numero = parseInt(link.label, 10);
        let txtboton = '';
        let pagActiva = 1;
        let classActiva = '';

        if(!isNaN(numero) && Number.isInteger(numero)){
          txtboton = numero;

          if(pagActiva == numero){
            classActiva = 'activa';
          }

        }
        else{
          if(link.label.toLowerCase().includes('next')){
              txtboton = '>>';
          }
          else if(link.label.toLowerCase().includes('prev')){
              txtboton = '<<';
          }
          else{
            txtboton = '';
          }
          
        }

        return (
          <Link className={`alx-link-paginado ${classActiva}`}
            to={`/noticias/resultados/all/all/1?page=${pageValue}`}
            key={i}
          >
            {txtboton}
          </Link>
        );
      });

    if(cantBotonesPag > 1){
      paginacionHTML = <div className="alx-pagination">{paginacionItems}</div>;
    }  
    
  }

  return (
    <div className='row'>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {data?.news.data ? (
            data?.news.data.length === 0 ? (
              <>No results</>
            ) : (
              <>
                {data.news.data.map((noticia) => (
                  <div className="col-xs-12 col-md-4" key={noticia.id}>
                    <Link to={'/noticias/' + noticia.slug} onClick={() => window.scrollTo(0, 0)} className='alx-receta-min'>
                      <img
                        src={BASE_URL + '/uploads/noticias/' + noticia.imagen_mobile}
                        alt=""
                        className='w-auto img-fluid'
                      />
                      <div className='alx-color-cafe'>{noticia.titulo}</div>
                    </Link>
                  </div>
                ))}

                <div className="col-xs-12" id="alx-paginado">
                  {paginacionHTML}
                </div>
              </>
            )
          ) : (
            <Preloader />
          )}
        </>
      )}
    </div>
  );
};

export default NewsGrid;