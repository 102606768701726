import React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import './post.css';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


class Post extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            noticia: {},
            relatedPosts: [],
            loading: true,
            loadingRelated: true,
        }
    }

    async componentDidMount() {
        let { id } = this.props.params;
        await this.getPostInfo(id);
        await this.getRelatedPosts(id);
    }

    getPostInfo = async (id) => {
        await fetch(process.env.REACT_APP_API_ENDPOINT_URI+"api/noticia/" + id, {
            method: "GET"
        })
            .then((response) => response.json())
            .then(
                (result) => {
                    this.setState({ noticia: result });
                },
                (error) => {
                    console.log(error);
                }
            )
            .finally(
                this.setState({ loading: false })
            );
    }

    getRelatedPosts = async (id) => {
        await fetch(process.env.REACT_APP_API_ENDPOINT_URI+"api/noticias-relacionadas/" + id, {
            method: "GET"
        })
            .then((response) => response.json())
            .then(
                (result) => {
                    this.setState({ relatedPosts: result });
                },
                (error) => {
                    console.log(error);
                }
            )
            .finally(
                this.setState({ loadingRelated: false })
            );
    }

    copyLink = () => {
        var copyText = window.location.href;
        document.getElementById("fixedCopied").style.opacity = 1;
        setTimeout(function () {
            document.getElementById("fixedCopied").style.opacity = 0;
        }, 3000);
        navigator.clipboard.writeText(copyText);
    }


    render() {

        return (
            <>
                <div id="Noticia" className="mx-5">
                    <section className="article mx-auto">
                        <div className="container">
                            <div className="row" st>
                                <p className="text-left mx-2 mt-5">
                                    <b>Fecha {moment(this.state.noticia.created_at).format('L')}</b></p>
                            </div>
                            <div className="row">
                                <h1 className="mainTitlePost">
                                    {this.state.noticia.title}
                                </h1>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" id="body-inner-col">

                                    <div dangerouslySetInnerHTML={{ __html: this.state.noticia.body }} />

                                    <img src={process.env.REACT_APP_API_ENDPOINT_URI+"storage/" + this.state.noticia.image} className="img-fluid shadow-lg my-1"
                                        alt={"Imagen de "+this.state.noticia.title} />

                                    <div id='divisor-hr'><hr /></div>
                                    <div className="rrss my-4 d-flex ">
                                        <a name="fb_share" href="" className="mx-2 icon">
                                            <FontAwesomeIcon icon={faFacebookF} />
                                        </a>
                                        <a href="" className="mx-2 icon">
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </a>
                                        <a href="" className="mx-2 icon">
                                            <FontAwesomeIcon icon={faLinkedinIn} />
                                        </a>
                                        <a className="mx-2 icon" onClick={() => this.copyLink()}>
                                            <FontAwesomeIcon icon={faLink} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section className="other-news mx-5">
                    <div className="container my-4 mw-50 ml-3">
                        <div className="row">
                            <h5 id="othersTitle">Otras Noticias</h5>
                        </div>
                        <div className="row">

                            {
                                (!this.state.loadingRelated) ?
                                    this.state.relatedPosts.map((noticia, i)=>{
                                        return <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6" key={i}>
                                            <a href="">
                                                <div className="card bg-white text-white">
                                                    <img src={ process.env.REACT_APP_API_ENDPOINT_URI+"storage/" + noticia.image } className="card-img" alt={"Imagen de "+noticia.title} />
                                                    <div className="card-img-overlay d-flex flex-column justify-content-between">
                                                        <h5 className="card-title shadow-text">FECHA {moment(noticia.created_at).format('L')}</h5>
                                                        <div className="d-flex flex-column justify-content-between">
                                                            <p className="card-text shadow-text linear-bottom">
                                                                { noticia.title }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                    })
                                :
                                <span>Cargando noticias relacionadas...</span>
                            }

                        </div>
                    </div>
                </section>

                <div id="fixedCopied">
                    Copied to clipboard!
                </div>

            </>
        )
    }
}

export default withParams(Post);