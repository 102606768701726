
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import RecipeComponent from '../../components/Recipe';
import BannerMiToqueIsadora from '../../components/BannerMiToqueIsadora';
import BannerUnSuperAlimento from '../../components/BannerUnSuperAlimento';
import "./styles.scss"

const Recipe = () => {
    let [recipe, setRecipe] = useState(null);

    let [recipesProductFilter, setRecipesProductFilter] = useState(null);
    let [recipesDifficultyFilter, setRecipesDifficultyFilter] = useState(null);
    let [recipesTimeFilter, setRecipesTimeFilter] = useState(null);

    const { pathname } = useLocation();

    useEffect(() => {

        const slug = pathname.substring(pathname.lastIndexOf('/') + 1);

        async function getFilters() {
            const res = await fetch(
                process.env.REACT_APP_API_ENDPOINT_URI+'api/get_all_recipes/0',
                {
                    method: 'GET',
                }
            );
            const data = await res.json();
            setRecipesProductFilter(recipesProductFilter = data.recipesProductFilter);
            setRecipesDifficultyFilter(recipesDifficultyFilter = data.recipesDifficultyFilter);
            setRecipesTimeFilter(recipesTimeFilter = data.recipesTimeFilter)
        }

        async function getRecipe() {
            const res = await fetch(
                process.env.REACT_APP_API_ENDPOINT_URI+'api/get_recipe/' + slug,
                {
                    method: 'GET',
                }
            );
            const data = await res.json();
            setRecipe((recipe = data.recipe));
        }
        getRecipe();
        getFilters();


    }, [])


    function navToRecipes(value, type) {
        window.location.href = "/recetas/" + value + '/' + type;
    }

    return (
        <>
            <div className='header-background'></div>
            <section className='recipes_filters_section'>
                <div
                    className="recipes_section "
                >
                    <div className='filter'>
                        <p className='section_subtitle'>FILTRA TU BÚSQUEDA</p>
                        <strong>Tipo de frijol</strong>
                        {recipesProductFilter ? (
                            recipesProductFilter?.map((prod, i) => {
                                return (
                                    <p
                                        key={i}
                                        className='filter_item'
                                        onClick={() => {
                                            navToRecipes(
                                                prod.product_id,
                                                'product'
                                            );
                                        }}
                                    >
                                        <input
                                            className='check_input'
                                            type='checkbox'
                                        />
                                        <span className='cap-each'>
                                            {prod.title.replace('Frijoles','')}
                                        </span>
                                        {/*prod.title*/}
                                        <span>({prod.total})</span>
                                    </p>
                                );
                            })
                        ) : (

                            <span><br></br>Cargando filtros...</span>
                        )}
                        <div className='space_box'></div>
                        <strong>Dificultad</strong>
                        {recipesDifficultyFilter ? (
                            recipesDifficultyFilter?.map(
                                (difficulty, i) => {
                                    return (
                                        <p
                                            key={i}
                                            className='filter_item'
                                            onClick={() => {
                                                navToRecipes(
                                                    difficulty.difficulty,
                                                    'difficulty'
                                                );
                                            }}
                                        >
                                            <input
                                                className='check_input'
                                                type='checkbox'
                                                key={i}
                                            />
                                            {difficulty.difficulty}
                                            <span>({difficulty.total})</span>
                                        </p>
                                    );
                                }
                            )
                        ) : (
                            <span><br></br>Cargando filtros...</span>
                        )}
                        <div className='space_box'></div>
                        <strong>Tiempo de realización</strong>

                        {recipesTimeFilter ? (
                            recipesTimeFilter?.map((time, i) => {
                                return (
                                    <p
                                        key={i}
                                        className='filter_item'
                                        onClick={() => {
                                            navToRecipes(
                                                time.cooking_time,
                                                'time'
                                            );
                                        }}
                                    >
                                        <input
                                            className='check_input'
                                            type='checkbox'
                                            key={i}
                                        />
                                        {time.cooking_time} min
                                        <span> ({time.total})</span>
                                    </p>
                                );
                            })
                        ) : (
                            <span><br></br>Cargando filtros...</span>
                        )}
                    </div>
                    <div className='recipe-grid'>
                        {recipe ?
                            <RecipeComponent recipe={recipe} ></RecipeComponent>
                            : null
                        }
                    </div>
                </div>
                <BannerMiToqueIsadora />
                <BannerUnSuperAlimento />
            </section>
        </>

    )
}

export default Recipe;