import { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { IsadoraLogo } from '../../assets/images';
import { NavBarToggle, NavBarSocials } from '../';
import { routes } from '../../views/Router/routes';
import styles from './Header.module.scss';

const Header = () => {
    /* Constantes */
    const { pathname } = useLocation();
    const { slug } = useParams();
    const [active, setActive] = useState(false);
    const {
        nav,
        navBrand,
        navBrandImage,
        navItem,
        navItemWhite,
        navItemBrown,
        navItemImage,
    } = styles;
    const params = window.location.href.split('/');
    const navItems = routes.filter(route => route.menu);
    navItems.splice(2, 0, {
        path: '/',
        text: <img src={IsadoraLogo} alt='Isadora' />,
    });
    const match = routes
        .map(route => {
            const path = route.path === null ? '/' : `/${route.path}`;
            return (
                ((path === pathname || path === `/${slug}`) &&
                'headerFontColor' in route ||
                params[params.length - 3] === "recetas") || 'error' in route
            );
        })
        .reduce((acc, result) => {
            result && (acc = result);
            if(params[3] === "mi-toque-isadora") return"brown";
            if(params[3] === "receta") return true;
            return acc;
        }, false);
    /* JSX <div id={pathname === '/news' ? 'alx-header-news' : 'alx-header'}>*/
    return (

        

        <div id={pathname.includes('noticias') ? 'alx-header-news' : 'alx-header'}>

        
        <Navbar expand='lg' className={`${nav} py-0 mx-auto pt-lg-4`}>
            <Container fluid='lg' className='px-0 d-lg-block position-relative'>
                <NavBarSocials color={match} />
                <Container
                    fluid
                    className='d-flex d-lg-none align-items-center'
                >
                    <Navbar.Brand
                        href='/'
                        className={`${navBrand} pt-0 pb-3 ps-2 text-center text-md-start d-lg-none`}
                    >
                        <img
                            src={IsadoraLogo}
                            alt='Isadora'
                            className={navBrandImage}
                        />
                    </Navbar.Brand>
                    <NavBarToggle
                        active={active}
                        eventHandler={setActive.bind(null, !active)}
                    />
                </Container>
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='mx-auto w-100 align-items-lg-center justify-content-lg-between'>
                        {navItems.map((item, i) => (
                            <Link
                                to={item.path}
                                key={i}
                                className={
                                    !item.text.type
                                        ? `${navItem} ${
                                                match == "brown" ? navItemBrown : match && navItemWhite
                                          } ${
                                              item.text === 'Contacto' &&
                                              'd-lg-none'
                                          } text-center position-relative text-uppercase pt-lg-3 alx-opc-menu`
                                        : `${navItemImage} d-none d-lg-block`
                                }
                                id={!item.text.type?`alx-opc-`+item.path:`alx-logo-header`}
                            >
                                {item.text}
                            </Link>
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </div>
    );
};

export default Header;
