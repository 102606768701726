import { useRef, createRef, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BannerUnSuperAlimento, NavegacionProductos } from '../../components';
import './styles.scss';
import { useLocation } from 'react-router';
import { ProductsLeaf } from '../../assets/images';
import RelatedRecipesCarousel from '../../components/RelatedRecipesCarousel';

const Productos = () => {
    const DOMElementRefs = useRef([...Array(2)].map(_ => createRef(null)));
    const [nutritionalTab, ingredientsTab] = DOMElementRefs.current;
    const imgUrl = process.env.REACT_APP_API_ENDPOINT_URI+'storage/';
    const { pathname } = useLocation();
    let [activetab, setActiveTab] = useState(true);
    let [product, setProduct] = useState([]);
    let [titleTop, setTitleTop] = useState('');
    let [titleBottom, setTitleBottom] = useState('');

    useEffect(() => {
        const slug = pathname.substring(pathname.lastIndexOf('/') + 1);
        async function getProductsWithSlug() {
            const res = await fetch(
                process.env.REACT_APP_API_ENDPOINT_URI+'api/get_product/' +
                    slug,
                {
                    method: 'GET',
                }
            );
            const data = await res.json();
            if (data.product == null) {
                window.location.href = '/producto-no-existe';
            }
            setProduct((product = data.product));
            setTitleTop(
                (titleTop = data.product.title.split(' ').slice(0, 2).join(' '))
            );
            setTitleBottom(
                (titleBottom = data.product.title
                    .split(' ')
                    .slice(2, 10)
                    .join(' '))
            );
        }
        async function getProducts() {
            const res = await fetch(
                process.env.REACT_APP_API_ENDPOINT_URI+'api/products',
                {
                    method: 'GET',
                }
            );
            const data = await res.json();
            setProduct((product = data.product));
            setTitleTop(
                (titleTop = data.product.title.split(' ').slice(0, 2).join(' '))
            );
            setTitleBottom(
                (titleBottom = data.product.title
                    .split(' ')
                    .slice(2, 10)
                    .join(' '))
            );
        }
        if (slug === 'productos') {
            getProducts();
        } else {
            getProductsWithSlug();
        }
    }, [pathname]);

    return (
        <>
            <NavegacionProductos />
            <section className='Product_section mx-auto'>
                <Container>
                    <Row>
                        <Col className='prod_details_col'>
                            <img src={ProductsLeaf} alt='' />
                            <h2 className='title_top nickainleyReg'>
                                {titleTop}
                            </h2>
                            <h2 className='title_bottom'>{titleBottom}</h2>
                            <p className='robotoRegular'>{product.details}</p>

                            <div
                                className='tab hk-grotesk'
                                ref={nutritionalTab}
                            >
                                {activetab ? (
                                    <div>
                                        <button
                                            className='tablinks  active_tab'
                                            onClick={() => setActiveTab(true)}
                                        >
                                            Declaración nutrimental
                                        </button>
                                        <button
                                            className='tablinks disactivated_Tab '
                                            onClick={() => setActiveTab(false)}
                                        >
                                            Ingredientes
                                        </button>
                                    </div>
                                ) : (
                                    <div>
                                        <button
                                            className='tablinks disactivated_Tab '
                                            onClick={() => setActiveTab(true)}
                                        >
                                            Declaración nutrimental
                                        </button>
                                        <button
                                            className='tablinks active_tab'
                                            onClick={() => setActiveTab(false)}
                                        >
                                            ingredientes
                                        </button>
                                    </div>
                                )}
                            </div>
                            {activetab ? (
                                <div
                                    className='tabcontent'
                                    ref={ingredientsTab}
                                >
                                    <img
                                        className='nutritional_img'
                                        src={
                                            imgUrl + product.nutricional_details
                                        }
                                        alt=''
                                    />
                                </div>
                            ) : (
                                <div className='tabcontent'>
                                    <p>{product.ingredients}</p>
                                </div>
                            )}
                        </Col>
                        <Col className='prod_details_col order-first order-md-last'>
                            <div
                                className='img_container'
                                style={{
                                    backgroundImage:
                                        "url('" +
                                        imgUrl +
                                        product.backside_imagen +
                                        "')",
                                }}
                            >
                                <img
                                    src={imgUrl + product.active_image}
                                    alt=''
                                />
                                <p>
                                    Pasa el puntero sobre la imagen para ver el
                                    reverso.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <RelatedRecipesCarousel />
            <BannerUnSuperAlimento />
        </>
    );
};

export default Productos;
