import {
    BPlatoEnterosClaros,
    BPlatoEnterosNegros,
    BRefritosPlatoBajosGrasa,
    BRefritosPlatoBayos,
    BRefritosPlatoChorizo,
    BRefritosChicharron,
    BRefritosPlatoPeruanos,
    BRefritosPlatoNegros,
    PlatoEnterosNegros,
    PlatoEnterosClaros,
    PlatoRefritosBayos,
    PlatoRefritosBajosEnGrasa,
    PlatoRefritosChicharron,
    PlatoRefritosChorizo,
    PlatoRefritosNegros,
    PlatoRefritosPeruanos,
    PouchArrozBlancoElote,
    PlatoArrozBlancoElote,
    PouchArrozBlancoSazonado,
    PlatoArrozBlancoSazonado,
    PouchArrozMexicana,
    PlatoArrozMexicana,
} from '../../assets/images';

const data = [
    {
        pathname: '/productos',
        text: 'Frijoles Refritos Bayos',
        img: PlatoRefritosBayos,
        imgHover: BRefritosPlatoBayos,
        clase: '',
    },
    {
        pathname: '/producto/frijoles-refritos-peruanos',
        text: 'Frijoles Refritos Peruanos',
        img: PlatoRefritosPeruanos,
        imgHover: BRefritosPlatoPeruanos,
        clase: '',
    },
    {
        pathname: '/producto/frijoles-refritos-negros',
        text: 'Frijoles Refritos Negros',
        img: PlatoRefritosNegros,
        imgHover: BRefritosPlatoNegros,
        clase: '',
    },
    {
        pathname: '/producto/frijoles-refritos-bayos-sin-grasa',
        text: 'Frijoles Refritos Bayos sin Grasa',
        img: PlatoRefritosBajosEnGrasa,
        imgHover: BRefritosPlatoBajosGrasa,
        clase: '',
    },
    {
        pathname: '/producto/frijoles-refritos-con-chorizo',
        text: 'Frijoles Refritos con Chorizo',
        img: PlatoRefritosChorizo,
        imgHover: BRefritosPlatoChorizo,
        clase: '',
    },
    {
        pathname: '/producto/frijoles-refritos-con-chicharron',
        text: 'Frijoles Refritos con Chicharrón',
        img: PlatoRefritosChicharron,
        imgHover: BRefritosChicharron,
        clase: '',
    },
    {
        pathname: '/producto/frijoles-enteros-claros',
        text: 'Frijoles Enteros Claros',
        img: PlatoEnterosClaros,
        imgHover: BPlatoEnterosClaros,
        clase: '',
    },
    {
        pathname: '/producto/frijoles-enteros-negros',
        text: 'Frijoles Enteros Negros',
        img: PlatoEnterosNegros,
        imgHover: BPlatoEnterosNegros,
        clase: '',
    },
    {
        pathname: '/producto/arroz-blanco-con-elote',
        text: 'Arroz Blanco con Elote',
        img: PouchArrozBlancoElote,
        imgHover: PlatoArrozBlancoElote,
        clase: 'alx-plato-img-top',
    },
    {
        pathname: '/producto/arroz-blanco-sazonado',
        text: 'Arroz Blanco Sazonado',
        img: PouchArrozBlancoSazonado,
        imgHover: PlatoArrozBlancoSazonado,
        clase: 'alx-plato-img-top',
    },
    {
        pathname: '/producto/arroz-a-la-mexicana',
        text: 'Arroz a la Mexicana',
        img: PouchArrozMexicana,
        imgHover: PlatoArrozMexicana,
        clase: 'alx-plato-img-top',
    },
];

export default data;
