import { BackGroundImageHero, BannerUnSuperAlimento } from '../../components';
import {
    HomeSlider,
    ComoHechosEnCasa,
    ContactUsBanner,
} from '../../components/HomePage';
import PopUpCookies from '../../components/PopUpCookies';
import './home.css';


const Home = () => {    
    return (
        <>
            <BackGroundImageHero />
            <ComoHechosEnCasa />
            <HomeSlider />
            <BannerUnSuperAlimento />
            <ContactUsBanner />
            <PopUpCookies />
        </>
    );
};

export default Home;
