import React from 'react';
import "./Recipe.css"
import { FacebookIcon, TwitterIcon, PinterestGreen450 } from "../../assets/images/index";
import { FacebookShareButton, TwitterShareButton } from "react-share";


class Recipe extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <section className='single_recipe_section'>
                { /* Meta tags para Pinterest */ }
                    <meta property="og:site_name" content="frijolesisadora.com" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="Receta" />
                    <meta property="og:title" content={this.props.recipe.title} />
                    <meta property="og:description" content={this.props.recipe.description} />
                    <meta property="og:image" content={process.env.REACT_APP_API_ENDPOINT_URI+'storage/recipes/' + this.props.recipe.image } />

                    <div style={{display:"none"}} itemScope itemType="http://schema.org/Recipe">
                        <h1 itemProp="name">{this.props.recipe.title}</h1>
                        <meta itemProp="url" content={window.location.href} />
                        <span itemProp="totalTime"> {this.props.recipe.cooking_time} minutos</span>
                        Ingredients:
                        {
                            this.props.recipe.ingredient?.map((ingredient, i) => {

                                return (ingredient.is_subtitle) ?
                                    <span itemProp="ingredients" key={i}>{ingredient.description}</span>
                                    :    
                                    <span itemProp="ingredients" key={i}>{ingredient.description}</span>
                            })
                        }
                    </div>
                { /* Meta tags para Pinterest */ }
                <img className="single_recipe_img" src={process.env.REACT_APP_API_ENDPOINT_URI+'/storage/recipes/' + this.props.recipe.image } />
                <h2 className='single_recipe_title' >{this.props.recipe.title}</h2>
                <p className='single_recipe_description color_brown' >{this.props.recipe.description}</p>
                <div className='underine_bar' ></div>
                <div className='single_recipe_time_difficulty_socials_container'>
                    <div className='single_recipe_time_difficulty_container'>
                        <div className='single_recipe_time_container'>
                            <p className='color_orange'>Tiempo de realizacion</p>
                            <p className='fw-bold color_brown'> {this.props.recipe.cooking_time} minutos</p>
                        </div>
                        <div className='single_recipe_difficulty_container'>
                            <p className='color_orange'>Dificultad</p>
                            <p className='fw-bold color_brown'> {this.props.recipe.difficulty}</p>
                        </div>
                    </div>
                    <div className='social_container'>
                    <a data-pin-do="buttonBookmark" data-pin-tall="true" data-pin-round="true" href="https://www.pinterest.com/pin/create/button/">
                        <img src={PinterestGreen450} height="26" width="26" style={{ marginRight: "25px"}} />
                    </a>
                    <FacebookShareButton
                                url={window.location.href}
                                quote={this.props.recipe.title}
                                description={this.props.recipe.description}>
                                <img className='facebook_logo' src={FacebookIcon} />
                    </FacebookShareButton>
                    <TwitterShareButton
                                title={this.props.recipe.title}
                                url={window.location.href}
                                hashtags={["Isadora", "RecetasIsadora"]}>
                                <img className='twitter_logo' src={TwitterIcon} />
                    </TwitterShareButton>        
                    </div>
                </div>
                <div className='underine_bar' ></div>
                <h5 className='second_recipe_titles' >ingredientes</h5>
                {
                    this.props.recipe.ingredient?.map((ingredient, i) => {

                        return (ingredient.is_subtitle) ?
                            <p key={i} className="sub_ingredient color_brown">
                                {ingredient.description}
                            </p>
                            :   <p key={i} className="ingredient color_brown">
                            {ingredient.description}
                        </p>
                    })
                }
               
                <div className='underine_bar' ></div>
                <h5 className='second_recipe_titles' >preparacion</h5>
                <p className='color_brown' dangerouslySetInnerHTML={{ __html: this.props.recipe.preparation.replace(/(?:\r\n|\r|\n)/g, '<br />') }} ></p>
            </section>
        )
    }
}

export default Recipe;