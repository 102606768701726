import React from 'react';
import "./BannerMiToqueIsadora.css"
import { MiToqueIsadoraBlanco } from "../../assets/images/index";

class BannerMiToqueIsadora extends React.Component {

  
    render() {

        return (
            <section className='banner-seccion d-flex justify-content-center'>
                <div className=' flex-wrap justify-content-center  d-flex align-items-center' > 
                    <div className='isadora_logo_container'>
                        <img src={MiToqueIsadoraBlanco}/>
                    </div>
                    <div className='banner_content'>
                        <p>Prácticos consejos que gente como tú nos</p>
                        <p>comparte para darle un toquecito a sus frijoles</p>
                        <p>Isadora. ¿Cúal es tu secreto? Comparte con</p>
                        <p>nosotros el toque especial que le das a tus platillos. </p>
                        <button className='banner_btn' onClick={() => window.location.href = "/mi-toque-isadora"}>CONOCE MÁS</button>
                    </div>
                </div>
            </section>
        )
    }
}

export default BannerMiToqueIsadora;