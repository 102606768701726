import { Row, Col } from 'react-bootstrap';
import { Btn } from '../';
import {
    claimHomeFrijolesIsadora,
    PlatoPrincipalHome,
    flechaBlancaDerecha,
} from '../../assets/media';
import styles from './BackGroundImageHero.module.scss';

const ContentHeroHomepage = () => {
    const {
        heroMainTitleContainer,
        heroMainTitle,
        heroMainImgPlato,
        heroMainImgFlecha,
        heroMainDescriptionHomeContainer,
        heroMainDescriptionHomeContainerOverride,
    } = styles;
    return (
        <>
            <Row>
                <Col
                    className={`${heroMainTitleContainer} mx-auto text-center`}
                >
                    <img
                        src={claimHomeFrijolesIsadora}
                        className='img-fluid'
                        alt=''
                    />
                    <h1 className={`${heroMainTitle} mx-auto text-white`}>
                        ¡Los frijoles refritos en bolsa #1 de México!
                    </h1>
                </Col>
            </Row>
            <Row className='pt-md-3'>
                <Col xl={3} className='d-none d-xl-block'></Col>
                <Col
                    md={9}
                    lg={7}
                    xl={6}
                    className={`order-1 order-md-0 position-relative text-center`}
                >
                    <img
                        src={PlatoPrincipalHome}
                        className={`w-100 ${heroMainImgPlato} animated fadeInUp position-relative`}
                        alt=''
                    />
                </Col>
                <Col
                    md={3}
                    lg={5}
                    xl={3}
                    className={`order-0 ${heroMainDescriptionHomeContainer} ${heroMainDescriptionHomeContainerOverride} position-relative`}
                >
                    <img
                        src={flechaBlancaDerecha}
                        className={`img-fluid ${heroMainImgFlecha} d-none d-md-block animated fadeInLeft position-relative`}
                        alt=''
                    />
                    <p className={`text-white py-3 py-lg-4`}>
                        <strong>
                            Isadora es la marca de frijoles en bolsa #1 de
                            México, elaborados con recetas caseras y sin
                            conservadores. ¡Pruébalos en sus distintas
                            presentaciones!
                        </strong>
                    </p>
                    {['producto', 'receta'].map((btn, i) => (
                        <Btn text={`Ver ${btn}`} link={`${btn}s`} key={i} />
                    ))}
                </Col>
            </Row>
        </>
    );
};

export default ContentHeroHomepage;
