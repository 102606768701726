import { HomeSlider } from '../../components/HomePage';
import { Btn } from '../../components';
import { bg404, searchIcon } from '../../assets/media';
import './styles.scss';

const Page404 = () => {
    return (
        <>
            <div className='header-background'></div>
            <section id="not-found">
                <h2>UPS! LA PÁGINA NO EXISTE</h2>
                <p>Pero no te desanimes, intenta encontrar lo que buscas utilizando los menús superior o inferior de esta página o ingresa tu búsqueda en este recuadro:</p>
                <div class="search-flex">
                    <button>
                        <img alt="Loop" src={searchIcon} />
                    </button>
                    <input type="search" id="search-404" name="search-404" placeholder="¿Qué estas buscando?" /> </div>
                    {/*<section className='page_404_section'>
                        <div className='page_404'>
                            <h2 className='page_not_found'>PÁGINA NO ENCONTRADA</h2>
                            <p>
                                La página que estás buscando no existe. Da clic en el
                                siguiente botón para ir al inicio.
                            </p>
                            <Btn text='Regresar a Inicio' link='/' />
                        </div>
                        <HomeSlider />
                    </section>*/}
            </section>
        </>
    );
};

export default Page404;
