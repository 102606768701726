import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import ContentHeroHomepage from './ContentHeroHomepage';
import ContentHeroMiToqueIsadora from './ContentHeroMiToqueIsadora';
import ContentHeroFrijolSuperAlimento from './ContentHeroFrijolSuperAlimento';
import styles from './BackGroundImageHero.module.scss';

const BackgroundImageHero = () => {
    const { pathname } = useLocation();

    const { hero, heroHomepage, heroMiToqueIsadora, heroFrijolSuperAlimento } =
        styles;

    const currentPage = [
        {
            pathname: '/',
            class: heroHomepage,
            content: <ContentHeroHomepage />,
        },
        {
            pathname: '/mi-toque-isadora',
            class: heroMiToqueIsadora,
            content: <ContentHeroMiToqueIsadora />,
        },
        {
            pathname: '/frijol-un-super-alimento',
            class: heroFrijolSuperAlimento,
            content: <ContentHeroFrijolSuperAlimento />,
        },
    ]
        .filter(page => page.pathname === pathname)
        .reduce(page => page);

    return (
        <section
            className={`${hero} ${currentPage.class} ${
                pathname !== '/' && 'd-flex align-items-center'
            } position-relative`}
        >
            <Container fluid='md' className='position-relative'>
                {currentPage.content}
            </Container>
        </section>
    );
};

export default BackgroundImageHero;
