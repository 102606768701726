import { createContext, useReducer } from 'react';

export const globalContext = createContext();

const GlobalState = ({ children }) => {
    const [state, dispatch] = useReducer(
        (state, entry) => {
            return {
                ...state,
                ...entry,
            };
        },
        {
            searchModalActive: false,
        }
    );
    return (
        <globalContext.Provider value={{ state, dispatch }}>
            {children}
        </globalContext.Provider>
    );
};

export default GlobalState;
