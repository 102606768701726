import React, { useEffect, useState } from 'react';
import { Btn } from '../../';
import './styles.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import useMediaQuery from '../../../Hooks/useMediaQuery';

const HomeSlider = () => {
    const urlPrefix = process.env.REACT_APP_API_ENDPOINT_URI+"storage/recipes/";
    const breakpoint = useMediaQuery('lg');
    let [recipes, setRecipes] = useState([]);
    let [loading, setLoading] = useState(true);
    useEffect(() => {
      
        async function getRandomRecipes() {
            const res = await fetch(
                process.env.REACT_APP_API_ENDPOINT_URI+'api/random_recipes/' + 0,
                {
                    method: 'GET',
                }
            );
            const data = await res.json();
            setRecipes((recipes = data.recipes));
            setLoading((loading = false));
        }

        getRandomRecipes();
    }, [loading])

    function goToRecipe(slug) {
        window.location = "/receta/" + slug;
    }

    return (
        <section id='slide-home'>
            <div className='container animated fadeInUp'>
                <h3 className='brown_color_text'>
                    Prepara recetas <b>increíbles</b>
                </h3>
                <p className='brown_color_text'> 
                    Para una tarde de películas, una comida familiar o para el lunch
                    de todos los días. ¡Los frijoles Isadora son perfectos para toda
                    ocasión! Conoce los ricos y sencillos platillos que puedes
                    preparar para cumplir todos tus antojos.
                </p>
                <Btn text='Ver todas las recetas' link='recetas' />

                <OwlCarousel className='owl-carousel-home' margin={15} loop nav items={breakpoint ? 3 : 2}>
                    {   !loading ? 
                        recipes?.map((recipe, i) => {
                            return <div className='item' key={i} onClick={() => { goToRecipe(recipe.slug) }}>
                                <img src={urlPrefix + recipe.image} alt="Imagen de la receta" />
                                <p className='recipe_title brown_color_text'>{recipe.title}</p>
                            </div>
                        }) : null}
                </OwlCarousel>
            </div>
        </section>
    )
}
export default HomeSlider;
