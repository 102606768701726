import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import useMediaQuery from '../../Hooks/useMediaQuery';

import "./styles.scss";



const RelatedRecipesCarousel = () => {
    let [loading, setLoading] = useState(true);
    const urlPrefix = process.env.REACT_APP_API_ENDPOINT_URI+"storage/recipes/";
    const breakpoint = useMediaQuery('lg');
    const slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    const title = slug.split('-')
        .slice(0, 10)
        .join(' ');
    let [recipes, setRecipes] = useState([]);

    useEffect(() => {

        async function getRelatedRecipes() {
            const res = await fetch(
                process.env.REACT_APP_API_ENDPOINT_URI+'api/related_recipes/' + slug,
                {
                    method: 'GET',
                }
            );
            const data = await res.json();
            setRecipes((recipes = data.recipes));
            setLoading((loading = false));

        }

        getRelatedRecipes();
    }, [loading])
    
    function goToRecipe(slug) {
        window.location = "/receta/" + slug;
    }
    return (
        <section className='related_recipes_section'>
            <h2 className="related-recipes-title">Recetas preparadas con</h2>
            <h2 className="related-recipes-product-title">{title == "productos" ? 'FRIJOLES REFRITOS BAYOS' : title}:</h2>
            <OwlCarousel margin={15} loop nav items={breakpoint ? 3 : 2}>
                {(!loading ) ?
                    recipes?.map((recipe, i) => {
                        return <div className='item' key={i} onClick={() =>{ goToRecipe(recipe.slug)}}>
                            <img src={ urlPrefix + recipe.image} alt="Imagen de la receta" />
                            <p className='recipe_title'>{recipe.title}</p>
                        </div>
                    }) : null}
            </OwlCarousel>
        </section>
    )
}

export default RelatedRecipesCarousel;