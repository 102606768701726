import { bg404, searchIcon } from '../../assets/media';
import './styles.scss';

const SinResultados = (props) => {
    return (
        <>
            <div className='header-background'></div>
            <section id="not-found">
                <p>La búsqueda de <strong>{ props.searched }</strong> arrojó 0 resultados. <br />Intente repetir la búsqueda con distintos términos.</p>
                <div className="search-flex">
                    <button>
                        <img alt="Loop" src={searchIcon} />
                    </button>
                    <input type="search" id="search-404" name="search-404" placeholder="¿Qué estas buscando?" /> </div>
            </section>
        </>
    );
};

export default SinResultados;
