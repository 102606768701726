import React from 'react';
import moment from 'moment';
import SuscribeNewsletter from '../../components/SuscribeNewsletter';
import './blog.css';
import { Link } from 'react-router-dom';

class Blog extends React.Component {

    constructor(props) {
        super(props);   
        this.state = {
            noticias: [],
            loading: true,
        }
    }

    async componentDidMount(){
        await this.getParticipations();
    }

    getParticipations = async () => { 
        await fetch(process.env.REACT_APP_API_ENDPOINT_URI+"api/noticias", {
            method: "GET"
        })
            .then((response) => response.json())
            .then(
                (result) => {
                    this.setState({ noticias: result });
                },
                (error) => {
                    console.log(error)
                    return;
                }
            )
            .finally(
                this.setState({loading: false})
            );
    }


    render() {

        return (
            <>
                <div id="Noticias">
                    <div className="container" id="noticiasContainer">
                        <div className="row" id="filaNoticias">

                            {
                                (!this.state.loading) ?
                                    this.state.noticias.map((noticia, i)=>{
                                        return <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 my-3 each-post-col" key={i}>
                                            <Link to={"/noticia/"+noticia.id}>
                                                <div className="card bg-white text-white" >
                                                    <img src={ process.env.REACT_APP_API_ENDPOINT_URI+"storage/" + noticia.image} className="card-img" alt={"Imagen de "+noticia.title} />
                                                    <div className="card-img-overlay d-flex flex-column justify-content-between">
                                                        <h5 className="card-title shadow-text">{ moment(noticia.created_at).format('L') }</h5>
                                                        <div className="d-flex flex-column justify-content-between">
                                                            <p className="card-text shadow-text linear-bottom">
                                                                { noticia.title }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    })
                                :
                                <span>Cargando noticias...</span>
                            }

                        </div>
                    </div>
                    <SuscribeNewsletter />
                </div>

            </>
        )
    }
}

export default Blog;