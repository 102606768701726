import { useState, useEffect, useReducer } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
/* import { timeOut } from './setTimeout'; */

export const useMediaQuery = breakpoint => {
    const getMediaQuery = () => {
        const size =
            breakpoint === 'sm'
                ? 576
                : breakpoint === 'md'
                ? 768
                : breakpoint === 'lg'
                ? 992
                : breakpoint === 'xl'
                ? 1200
                : breakpoint === 'xxl'
                ? 1440
                : 1650;
        return window.matchMedia(`(min-width: ${size}px)`).matches;
    };
    const [match, setIsMatch] = useState(getMediaQuery);

    useEffect(() => {
        window.addEventListener('resize', setIsMatch.bind(null, getMediaQuery));
        return window.removeEventListener(
            'resize',
            setIsMatch.bind(null, getMediaQuery)
        );
    }, []);
    return match;
};

export const useFetch = endPoint => {
    const [state, dispatch] = useReducer(
        (state, action) => {
            return { ...state, ...action };
        },
        { isLoading: false, data: null, isError: null }
    );

    const fetchData = async endPoint => {
        try {
            dispatch({ isLoading: true });
            const response = await fetch(endPoint);
            dispatch({ data: await response.json() });
        } catch (error) {
            console.log(error);
            dispatch({ isError: error });
        } finally {
            dispatch({ isLoading: false });
        }
    };

    useEffect(() => {
        fetchData(endPoint);
    }, [endPoint]);

    return state;
};

export const navigateTo = (e, pathname, to, fn) => {
    /* await timeOut(2000); */
    e.preventDefault();
    if (pathname === to) return;
    window.scrollTo(0, 0);
    fn(to);
};

export const useOperatingSystem = () => {
    if (/Windows/.test(navigator.userAgent)) return 'Windows';
    if (/Macintosh/.test(navigator.userAgent)) return 'Mac';
    if (/Linux/.test(navigator.userAgent)) return 'Linux';
};
