import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import './styles.scss';

class FacebookPosts extends React.Component {

    constructor(props) {
        super(props);   
        this.state = {
            posts: [],
            loading: true,
        }
    }

    async componentDidMount(){
        await this.getFacebookPosts();
    }
    
    async getFacebookPosts() { 
        fetch(process.env.REACT_APP_API_ENDPOINT_URI+"api/facebook-posts", {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then(
                (result) => {
                    this.setState({ posts: result });
                },
                (error) => {
                    console.log(error);
                }
            )
            .finally(
                this.setState({loading: false})
            );
    }

    render(){
        return (
            <Container>
                {
                    (!this.state.loading) ?
                    <Row className='Facebook_posts_container'>
                        {
                            this.state.posts.map((post, i) => {
                                return <Col md={3} key={i} >
                                    <iframe
                                        src={post.url}
                                        width="500"
                                        height="600"
                                        scrolling="no"
                                        frameBorder="0"
                                        allowFullScreen={true}
                                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
                                    </iframe>
                                </Col>
                            })
                        }
                    </Row>
                    :
                    <span>Cargando las últimas noticias...</span>
                }
            </Container>
        );
    }
    
}
export default FacebookPosts;
