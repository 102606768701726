import PropTypes from 'prop-types';
import { useContext } from 'react';
import { globalContext } from '../../Hooks/GlobalState';
import { Container } from 'react-bootstrap';
import { UsFlag } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import {
    faInstagram,
    faPinterest,
    faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons';
import './styles.scss';
import { useLocation } from 'react-router-dom';

const NavBarSocials = ({ color, footer }) => {
    const {
        state: { searchModalActive },
        dispatch,
    } = useContext(globalContext);
    const location = useLocation();
    const icons = [
        {
            icon: faPinterest,
            type: 'fa',
            link: 'https://www.pinterest.com.mx/FrijolesIsadora/',
        },
        {
            icon: faInstagram,
            type: 'fa',
            link: 'https://www.instagram.com/isadoramexico_/',
        },
        {
            icon: faFacebookSquare,
            type: 'fa',
            link: 'https://www.facebook.com/IsadoraMexico',
        },
        { icon: faMagnifyingGlass, type: 'fa' },
        {
            icon: UsFlag,
            type: 'img',
            link: 'https://isadorabeans.com/',
        },
    ];
    const handleClick = () => {
        dispatch({
            searchModalActive: !searchModalActive,
        });
        document.documentElement.style.overflow = 'hidden';
        console.log(location.pathname);
    };

    return (
        <Container
            fluid
            className={`d-flex justify-content-${
                footer ? 'start px-0 position-relative' : 'end'
            } social-icons mt-2 mt-lg-0 `} 
            id='alx-rs-header'
        >
            <div className='d-flex justify-content-center align-items-center'>
                {icons.map((icon, i) =>
                    'link' in icon ? (
                        <a
                            href={icon.link}
                            rel='noreferrer'
                            className={`social-icons__link ${
                                icon.type === 'img' &&
                                'social-icons__link--usFlag'
                            } me-lg-2`}
                            key={i}
                        >
                            {icon.type === 'img' ? (
                                <img
                                    src={icon.icon}
                                    alt='Visita Isadora en EU'
                                    title='Visita Isadora en EU'
                                    className='img-fluid'
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={icon.icon}
                                    className={`${
                                        color != 'brown'
                                            ? color && 'text-white'
                                            : 'color_brown'
                                    } `}
                                />
                            )}
                        </a>
                    ) : (
                        <button
                            key={i}
                            className='social-icons__link social-icons__link--button me-lg-2 border-0'
                            onClick={handleClick}
                        >
                            <FontAwesomeIcon
                                icon={icon.icon}
                                className={`${
                                    color != 'brown'
                                        ? color && 'text-white'
                                        : 'color_brown'
                                } `}
                            />
                        </button>
                    )
                )}
            </div>
        </Container>
    );
};

NavBarSocials.defaultProps = {
    color: null,
    footer: null,
};

export default NavBarSocials;
