import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './NavegacionProductos.module.scss';

const NavegacionProducto = ({
    producto,
    breakpoint,
    eventHandler,
    navProperties,
}) => {
    //Constantes
    const {
        NavProductosListItem,
        NavProductosLink,
        NavProductosLinkActive,
        NavProductosImage,
        NavProductosImageActive,
        NavProductosImageSprite,
    } = styles;
    const { pathname } = useLocation();
    const match = value => value === producto.pathname;
    const image = (
        <>
            <div
                className={`position-absolute w-100 ${NavProductosImageSprite}`}
            ></div>
            <img
                src={producto.imgHover}
                alt={producto.text}
                className={`w-100 ${producto.clase}`}
            />
        </>
    );
    /* JSX */
    return (
        <li className={NavProductosListItem}>
            <Link
                to={producto.pathname}
                className={`${NavProductosLink} ${
                    match(pathname) ||
                    (match(navProperties.focus) && breakpoint)
                        ? NavProductosLinkActive
                        : ''
                } d-block text-center`}
                onMouseOver={eventHandler.bind(null, producto.pathname)}
                onMouseLeave={eventHandler.bind(null, '')}
            >
                {breakpoint && (
                    <div className='mb-3 position-relative'>
                        {match(pathname) ? (
                            <div className={NavProductosImageActive}>
                                {image}
                            </div>
                        ) : (
                            <>
                                <img
                                    src={producto.img}
                                    alt={producto.text}
                                    className={`w-100 ${NavProductosImage}`}
                                />
                                <div
                                    className={`position-absolute ${NavProductosImageActive}`}
                                >
                                    {image}
                                </div>
                            </>
                        )}
                    </div>
                )}
                <p className='mb-0'>{producto.text}</p>
            </Link>
        </li>
    );
};

NavegacionProducto.propTypes = {
    producto: PropTypes.object.isRequired,
    breakpoint: PropTypes.bool.isRequired,
    eventHandler: PropTypes.func.isRequired,
    navProperties: PropTypes.object.isRequired,
};

export default NavegacionProducto;
