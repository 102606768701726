import { useEffect, useState } from 'react';
import './styles.scss';
import { Container, Col, Row } from 'react-bootstrap';
import { HourGlassIcon, SignalIcon } from '../../assets/images/index';
import BannerMiToqueIsadora from '../../components/BannerMiToqueIsadora';
import SinResultados from '../../components/SinResultados';
const SearchResults = () => {
    const searchString = window.location.href.substring(
        window.location.href.lastIndexOf('/') + 1
    );
    let [data, setData] = useState(null);
    /* let [products, setProducts] = useState([]); */
    let imgUrl = process.env.REACT_APP_API_ENDPOINT_URI+'storage/';

    const seeRecipe = slug => {
        window.location = '/receta/' + slug;
    };

    const seeProduct = slug => {
        window.location = '/producto/' + slug;
    };
    const search = async () => {
        const res = await fetch(
            process.env.REACT_APP_API_ENDPOINT_URI+'api/search/' +
                searchString +
                '/0',
            {
                method: 'GET',
            }
        );
        const data = await res.json();
        console.log(data);
        setData(data);
    };

    useEffect(() => {
        search();
    }, []);

    return (
        <>
            <div className='header-background'></div>
            <section className='search_section'>
                <Container className='search-container'>
                    <h5 className='search-title'>resultados de la búsqueda</h5>
                    <Row>
                        
                        {data?.recipes ? (
                            (data?.recipes.length==0)
                            ?
                            <>
                            <style dangerouslySetInnerHTML={{__html: `
                            .search_section .search-container {
                                padding: 0;
                                margin: 0;
                                margin-right: 0 !important;
                                width: 100%;
                                min-width: 100%;
                            }
                            div.search-container>h5.search-title{
                                display: none
                            }`}}>
                            </style>
                                <SinResultados searched={searchString} />
                            </>
                            :
                            <>
                                {data.recipes.map((recipe, i) => {
                                    return (
                                        <Col sm={4} key={i}>
                                            <div
                                                className='recipe'
                                                onClick={() => {
                                                    seeRecipe(recipe.slug);
                                                }}
                                            >
                                                <div className='featuredImage'>
                                                    <img
                                                        className='recipe_img'
                                                        src={
                                                            process.env.REACT_APP_API_ENDPOINT_URI+'storage/recipes/' +
                                                            recipe.image
                                                        }
                                                    />
                                                    <p className='recipe_title'>
                                                        {recipe.title}
                                                    </p>
                                                    <p>
                                                        {recipe.description.substring(
                                                            0,
                                                            100
                                                        ) + '...'}
                                                    </p>
                                                    <div className='recipe_time_difficulty'>
                                                        <div className='diff_time_container'>
                                                            <p className='difficulty_container'>
                                                                <img
                                                                    className='signal_icon'
                                                                    src={
                                                                        SignalIcon
                                                                    }
                                                                />
                                                                Dificultad
                                                                <span className='difficulty'>
                                                                    {' '}
                                                                    {
                                                                        recipe.difficulty
                                                                    }
                                                                </span>
                                                            </p>
                                                            <p className='difficulty_container'>
                                                                <img
                                                                    className='hour_icon'
                                                                    src={
                                                                        HourGlassIcon
                                                                    }
                                                                />
                                                                <span className='difficulty'>
                                                                    {' '}
                                                                    {
                                                                        recipe.cooking_time
                                                                    }{' '}
                                                                    minutos
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                                {Object.values(data.products).map((prod, i) => {
                                    return (
                                        <Col sm={4} key={i}>
                                            <div
                                                className='recipe'
                                                onClick={() => {
                                                    seeProduct(prod.slug);
                                                }}
                                            >
                                                <div className='featuredImage'>
                                                    <img
                                                        className='prod_img'
                                                        src={
                                                            imgUrl +
                                                            prod.active_image
                                                        }
                                                    />
                                                    <p className='recipe_title'>
                                                        {prod.title}
                                                    </p>
                                                    <p>
                                                        {prod.details.substring(
                                                            0,
                                                            100
                                                        ) + '...'}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </>
                        ) : (
                            <>Cargando...</>
                        )}
                    </Row>
                </Container>
                <BannerMiToqueIsadora />
            </section>
        </>
    );
};

export default SearchResults;
