import React from 'react';
import './form.css';

class SuscribeNewsletter extends React.Component {


    submitNewsletter = () => {

        document.getElementById("error").style.display = "none";
        const email = document.getElementById("emailNewsletter").value;

        if(!email) {
            document.getElementById("error").style.display = "block";
            return;
        }

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
            })
        };

        fetch(process.env.REACT_APP_API_ENDPOINT_URI+"api/createLead", options)
            .then((response) => response.json())
            .then(
                (result) => {
                    if(result.Status === "Validation failed"){
                        document.getElementById("error").style.display = "block";
                        return;
                    }
                    if (result.Status === "Email Saved") {
                        document.getElementById("success").style.display = "block";
                        document.getElementById("submitLead").disabled = true;
                        return;
                    }
                },
                (error) => {
                    console.log(error);
                    document.getElementById("error").style.display = "block";
                    return;
                }
            );
    }

    render() {
        return (
            <div className="newsletter d-flex flex-column align-items-center">
                <p className="h3">Únete al Newsletter</p>
                <div className="form-inline" id="contactForm">
                    <div className="row mx-2">
                        <input className="form-control" 
                            type="email" name="email" id="emailNewsletter"
                            placeholder="Correo electrónico" />
                        <button className="btn btn-light" 
                            id="submitLead" 
                            type="submit"
                            onClick={()=>this.submitNewsletter()}>Suscribirse</button>
                    </div>
                </div>
                <br /><br />
                <div id="error">Por favor, complete con su correo electrónico</div>
                <div id="success">¡Gracias por suscribirse!</div>
            </div>
        )
    }
}

export default SuscribeNewsletter;