import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header, Footer, SearchModal } from '../../components';
import { HeaderBanner } from '../../components/HomePage';
import GlobalState from '../../Hooks/GlobalState';

const Layout = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        'scrollRestoration' in window.history &&
            (window.history.scrollRestoration = 'manual');
    }, [pathname]);

    return (
        <GlobalState>
            {pathname === '/' && <HeaderBanner />}
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
            <SearchModal />
        </GlobalState>
    );
};

export default Layout;
