import { useRef, useEffect, createRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {
    HomeVector1,
    HomeVector4,
    EmpaqueRefritosHome,
    FrijolesCaseroHome1,
    FrijolesCaseroHome2,
    Bloque3HomeIsadoraFrijolesDer,
    FrijolesIsadoraFlechaHome,
} from '../../../assets/media';
import styles from './ComoHechosEnCasa.module.scss';
import Btn from '../../Btn';

gsap.registerPlugin(ScrollTrigger);

const ComoHechosEnCasa = () => {
    const refs = useRef([...Array(4)].map(_ => createRef(null)));
    const [comoHechosEnCasaSection, platoFrijoles, cebolla, fondoOrnamental] =
        refs.current;
    const {
        comoHechosEnCasa,
        comoHechosEnCasaLeftImg,
        comoHechosEnCasaHeadline,
        comoHechosEnCasaDescription,
        comoHechosEnCasaFlecha,
        comoHechosEnCasaImgDesc,
        comoHechosEnCasaMiddleImg,
        comoHechosEnCasaMiddleImgSecond,
        comoHechosEnCasaRightColImgSecond,
    } = styles;

    useEffect(() => {
        const animatedElements = [
            comoHechosEnCasaSection.current.querySelectorAll('[data-animated]'),
        ];
        const scrollTrigger = {
            trigger: comoHechosEnCasaSection.current,
            start: `top top+=50%`,
        };

        comoHechosEnCasaSection.current &&
            gsap.from(animatedElements, {
                alpha: 0,
                y: (_, target) => {
                    return target.alt === 'Empaque Frijoles Isadora'
                        ? null
                        : '100%';
                },
                x: (_, target) => {
                    return target.alt === 'Empaque Frijoles Isadora'
                        ? '100%'
                        : null;
                },
                duration: 1,
                ease: 'power4',
                stagger: 0.2,
                scrollTrigger,
            });
        gsap.from(platoFrijoles.current, {
            x: '100%',
            alpha: 0,
            duration: 2,
            ease: 'power4',
            scrollTrigger,
        });
        gsap.fromTo(
            cebolla.current,
            {
                y: '-100%',
                alpha: 0,
            },
            {
                y: '5%',
                alpha: 1,
                duration: 2,
                delay: 0.5,
                ease: 'power4',
                scrollTrigger,
            }
        );
        gsap.from(fondoOrnamental.current, {
            alpha: 0,
            duration: 2,
            delay: 1,
            ease: 'power4',
            scrollTrigger,
        });
    }, []);

    return (
        <section
            className={`${comoHechosEnCasa} px-md-0`}
            ref={comoHechosEnCasaSection}
        >
            <Container fluid className='pt-lg-5'>
                <Row className={`align-items-center`}>
                    <Col className='d-none d-md-block' md={3}>
                        <div className='position-relative'>
                            <img
                                src={HomeVector1}
                                className={`${comoHechosEnCasaLeftImg} img-fluid`}
                                alt='Plato de frijoles Isadora'
                                ref={platoFrijoles}
                            />
                            <div className='position-relative d-flex'>
                                <img
                                    src={FrijolesIsadoraFlechaHome}
                                    className={`${comoHechosEnCasaFlecha} d-block`}
                                    alt=''
                                    data-animated
                                />
                                <p
                                    className={`${comoHechosEnCasaImgDesc} px-2 pt-4 mb-4 ps-lg-5`}
                                    data-animated
                                >
                                    Sólo las mejores y más frescas cosechas son
                                    elegidas para elaborar tus frijoles Isadora.
                                </p>
                            </div>
                            <img
                                src={HomeVector4}
                                className={`${comoHechosEnCasaLeftImg} img-fluid pt-4}`}
                                alt='Ingrediente - Cebolla'
                                ref={cebolla}
                            />
                        </div>
                    </Col>
                    <Col xs={12} md={6} className='px-0' data-animated>
                        <div className='text-center'>
                            <h2
                                className={`${comoHechosEnCasaHeadline} text-uppercase m-0`}
                            >
                                como hechos{' '}
                                <span>
                                    <strong>en casa</strong>
                                </span>
                            </h2>
                            <p
                                className={`${comoHechosEnCasaDescription} py-3 mx-auto`}
                            >
                                Los frijoles Isadora son los más ricos, recién
                                cocinaditos y empacados al vacío sin
                                conservadores. Pruébalos así solitos o agrega tu
                                toque personal.
                            </p>
                            <Btn
                                text='Ir a productos'
                                link='productos'
                                colorBlack
                            ></Btn>
                        </div>
                        <div className='position-relative text-center d-lg-flex justify-content-lg-around'>
                            <img
                                src={FrijolesCaseroHome1}
                                className={`${comoHechosEnCasaMiddleImg} img-fluid pt-5`}
                                alt='Frijoles caseros 1'
                            />
                            <img
                                src={FrijolesCaseroHome2}
                                className={`${comoHechosEnCasaMiddleImg} ${comoHechosEnCasaMiddleImgSecond} img-fluid pt-5`}
                                alt='Frijoles caseros 1'
                            />
                        </div>
                    </Col>
                    <Col
                        className={`d-none pe-0 position-relative d-md-flex align-items-center justify-content-end`}
                        md={3}
                    >
                        <img
                            src={EmpaqueRefritosHome}
                            className='img-fluid'
                            alt='Empaque Frijoles Isadora'
                            data-animated
                        />
                        <img
                            src={Bloque3HomeIsadoraFrijolesDer}
                            className={`${comoHechosEnCasaRightColImgSecond} img-fluid position-absolute`}
                            alt='Fondo ornamental'
                            ref={fondoOrnamental}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default ComoHechosEnCasa;
