import React from 'react';
import "./LoadScreen.css"
class LoadScreen extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className='spinner'>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
}

export default LoadScreen;