import React from 'react';
import './style.css';

import { useEffect } from 'react';
import { useFetch } from '../../utilities/hooks';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import FilterNews from '../../components/FilterNews';
import NewsGrid from '../../components/NewsGrid';
import Preloader from '../../components/Preloader';
import ShareNavNews from '../../components/ShareNavNews';
import RelatedNews from '../../components/RelatedNews';



import {
    BannerNews
} from '../../assets/images';

const SingleNews = () => {
    const BASE_URL = process.env.REACT_APP_API_BASE_URL_NEWS;
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { slug } = useParams();
    /*const slug = 'isadora-teases-debut-of-ready-to-eat-rice-at-expo-west';*/
    const { data } = useFetch(`${BASE_URL}/api/news/detail/${slug}`);


    useEffect(() => {
        if (data) {
            /*console.log(data);*/
        }
        else {
          // Si no hay datos, navegar a la página de error 404
          
        }
    }, [pathname, data]);

    const handleBack = () => {
        window.history.back();
    };



    return (
        <>
            {data ? (

                !data.detail ? (
                   navigate('/404')
                ) : (
                <>
                    
                    <section id='News' className='alx-back-seccion'>

                    <div className='container d-none d-md-block'>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <a onClick={handleBack} className='alx-btn-atras text-brownIs'>Back</a>
                            </div>
                        </div>
                    </div>

                    <div className='container'>
                        <div className='row'>
                            <div className="col-xs-12 col-md-12 col-lg-3 order-lg-last d-none d-lg-block">
                                <FilterNews />
                            </div>

                            <div className="col-xs-12 col-md-12 col-lg-9 order-lg-first">
                                <div className="alx-banner alx-imagen-detalle-news">
                                <img
                                    src={
                                        BASE_URL +
                                        '/uploads/noticias/' +
                                        data.detail.imagen_desk


                                        
                                    }
                                    className='alx-img-full d-none d-md-block'
                                    alt={data.detail.titulo}
                                />
                                <img
                                    src={
                                        BASE_URL +
                                        '/uploads/noticias/' +
                                        data.detail.imagen_mobile


                                        
                                    }
                                    className='alx-img-full d-md-none'
                                    alt={data.detail.titulo}
                                />
                                </div>

                                <h1 className='alx-color-cafe alx-bold alx-titulo-noticia mb-4'>
                                    {data.detail.titulo}
                                </h1>

                                <div className='row mb-4'>
                                    <div className="col-6 alx-color-naranja alx-bold">
                                        {data.detail.created_at.substring(0, 10)}
                                    </div>
                                    <div className="col-6">
                                        <div id="alx-cont-share">
                                           <ShareNavNews
                                                image={BASE_URL +
                                        '/uploads/noticias/' +
                                        data.detail.imagen_mobile}
                                            />
                                        </div>
                                    </div>
                                </div>

                                
                                <div className='mb-5'>
                                <div
                                    className='alx-detalle-news-cuerpo alx-color-cafe' 
                                    dangerouslySetInnerHTML={{
                                        __html: data.detail.cuerpo
                                    }}
                                ></div>

                                {(data.detail.link_externo != '' && data.detail.link_externo_text != '') ? 
                                    <a href={data.detail.link_externo}
                                        target='_blank' 
                                        className='alx-link-externo'>
                                        {data.detail.link_externo_text}
                                    </a>
                                : 
                                <div></div>}

                              
                                </div>

                                

                            </div>
                            
                            
                        </div>
                    </div>
                    <div className='container d-md-none'>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <a onClick={handleBack} className='alx-btn-atras text-brownIs'>Back</a>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-9'>
                                <RelatedNews
                                    categoryId={data.detail.categoria_id}
                                    newsId={data.detail.id}
                                />
                            </div>
                        </div>
                    </div>
                    </section>






                   
                </>
            )) : (
                <Preloader />
            )}
        </>
    );
};

export default SingleNews;