import Home from '../Home';
import Recetas from '../Recipes';
import Receta from '../Recipe';
import MiToqueIsadora from '../MiToqueIsadora';
import Productos from '../Productos';
import FrijolSuperAlimento from '../FrijolSuperAlimento';
import Contacto from '../Contact';
import FAQ from '../FAQ';
import PrivacyPolicy from '../PrivacyPolicy';
import FrijolesRefritosPeruanos from '../FrijolesRefritosPeruanos';
import FrijolesRefritosNegros from '../FrijolesRefritosNegros';
import FrijolesRefritosBajosEnGrasa from '../FrijolesRefritosBajosEnGrasa';
import FrijolesRefritosConChorizo from '../FrijolesRefritosConChorizo';
import FrijolesRefritosConChicharron from '../FrijolesRefritosConChicharron';
import FrijolesEnterosClaros from '../FrijolesEnterosClaros';
import FrijolesEnterosNegros from '../FrijolesEnterosNegros';
import Post from '../Post';
import Blog from '../Blog';
import Page404 from '../404';
import SearchResults from '../SearchResults';
import News from '../News';
import SingleNews from '../SingleNews';
import ResultsNews from '../ResultsNews';

export const routes = [
    { component: <Home />, path: null, headerFontColor: 'white' },
    {
        component: <Recetas />,
        path: 'recetas',
        headerFontColor: 'white',
        text: 'Recetas',
        menu: true,
    },
    {
        component: <Recetas  withFilter={true} />,
        path: 'recetas/:value/:type',
        headerFontColor: 'white',
        text: 'Recipes',
    },
    { component: <Receta/>, path: 'receta/:slug' },
    {
        component: <MiToqueIsadora />,
        path: 'mi-toque-isadora',
        text: 'Mi toque Isadora',
        menu: true,
    },
    {
        component: <Productos />,
        path: 'productos',
        headerFontColor: 'white',
        text: 'Productos',
        menu: true,
    },
    { component: <Productos />, path: 'producto/:slug' },
    {
        component: <FrijolSuperAlimento />,
        path: 'frijol-un-super-alimento',
        text: 'Frijol, un súper alimento',
        menu: true,
    },
    {
        component: <Contacto />,
        path: 'contacto',
        text: 'Contacto',
        menu: true,
    },
    {
        component: <FAQ />,
        path: 'preguntas-frecuentes',
        text: 'Preguntas frecuentes',
        copyright: true,
    },
    {
        component: <PrivacyPolicy />,
        path: 'privacy-policy',
        text: 'Aviso de privacidad',
        copyright: true,
    },
    { component: <SearchResults />, path: 'resultados-de-busqueda/:search' },
    {
        component: <FrijolesRefritosPeruanos />,
        path: 'frijoles-refritos-peruanos',
        text: 'Frijoles Refritos Peruanos',
        headerFontColor: 'white',
    },
    {
        component: <FrijolesRefritosNegros />,
        path: 'frijoles-refritos-negros',
        text: 'Frijoles Refritos Negros',
        headerFontColor: 'white',
    },
    {
        component: <FrijolesRefritosBajosEnGrasa />,
        path: 'frijoles-refritos-bayos-sin-grasa',
        text: 'Frijoles Refritos Bayos sin Grasa',
        headerFontColor: 'white',
    },
    {
        component: <FrijolesRefritosConChorizo />,
        path: 'frijoles-refritos-con-chorizo',
        text: 'Frijoles Refritos con Chorizo',
        headerFontColor: 'white',
    },
    {
        component: <FrijolesRefritosConChicharron />,
        path: 'frijoles-refritos-con-chicharron',
        text: 'Frijoles Refritos con Chicharrón',
        headerFontColor: 'white',
    },
    {
        component: <FrijolesEnterosClaros />,
        path: 'frijoles-enteros-claros',
        text: 'Frijoles Enteros Claros',
        headerFontColor: 'white',
    },
    {
        component: <FrijolesEnterosNegros />,
        path: 'frijoles-enteros-negros',
        text: 'Frijoles Enteros Negros',
        headerFontColor: 'white',
    },
    /*{ component: <Post />, path: 'noticia/:id' },
    { component: <Blog />, path: 'noticias' , text : 'Noticias' ,  footer : true},
    */{ 
        component: <Page404 />,
        path: '*',
        headerFontColor: 'white',
        error: true,
    },
    {
        component: <News />,
        path: 'noticias',
        text: 'Noticias',
        menu: true,
    },
    {   component: <SingleNews/>, 
        path: 'noticias/:slug'
    },
    {   component: <ResultsNews/>, 
        path: '/noticias/resultados/:category/:date/:search'
    },
    /*{
        component: <News />,
        path: 'news',
        text: 'news',
        menu: true,
    },
    {   component: <SingleNews/>, 
        path: 'news/:slug'
    },
    {   component: <ResultsNews/>, 
        path: '/news/results/:category/:date/:search'
    },*/
];
