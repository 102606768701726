import React from 'react';
import './recipes.css';
import LoadScreen from '../../components/LoadScreen';
import RecipesCarousel from '../../components/RecipesCarousel';
import BannerMiToqueIsadora from '../../components/BannerMiToqueIsadora';
import BannerUnSuperAlimento from '../../components/BannerUnSuperAlimento';
import {
    HourGlassIcon,
    SignalIcon,
    searchIcon,
} from '../../assets/images/index';

class Recipes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recipes: [],
            recipesDifficultyFilter: [],
            recipesProductFilter: [],
            recipesTimeFilter: [],
            loading: true,
            productFilter: '',
            difficultyFilter: '',
            timeFilter: '',
            searchString: '',
        };
    }

    async componentDidMount() {
        if (this.props.withFilter) {
            const params = window.location.href.split('/');
            this.handleFilterEvent(params[params.length - 2], params[params.length - 1]);
        } else {
            await this.getRecipes();
        }
    }

    getRecipes = async () => {
        await fetch(process.env.REACT_APP_API_ENDPOINT_URI+'api/get_all_recipes/0', {
            method: 'GET',
        })
            .then(response => response.json())
            .then(
                result => {
                    this.setState({
                        recipes: result.recipes,
                        recipesDifficultyFilter: result.recipesDifficultyFilter,
                        recipesProductFilter: result.recipesProductFilter,
                        recipesTimeFilter: result.recipesTimeFilter,
                    });
                },
                error => {
                    console.log(error);
                    return;
                }
            )
            .finally(this.setState({ loading: false }));
    };

    handleFilterEvent = async (value, type) => {
        this.setState({
            loading: true,
        });

        await this.filter(value, type);
        await this.getFilterdRecipes();
    };

    filter = async (value, type) => {
        if (type === 'product') {
            if (this.state.productFilter === value) {
                this.setState({
                    productFilter: '',
                });
            } else {
                this.setState({
                    productFilter: value,
                });
            }
        }
        if (type === 'difficulty') {
            if (this.state.difficultyFilter === value) {
                this.setState({
                    difficultyFilter: '',
                });
            } else {
                this.setState({
                    difficultyFilter: value,
                });
            }
        }
        if (type === 'time') {
            if (this.state.timeFilter === value) {
                this.setState({
                    timeFilter: '',
                });
            } else {
                this.setState({
                    timeFilter: value,
                });
            }
        }
    };

    getFilterdRecipes = async () => {
        await fetch(
            process.env.REACT_APP_API_ENDPOINT_URI+'api/filter_recipes?productId=' +
            this.state.productFilter +
            '&difficulty=' +
            this.state.difficultyFilter +
            '&cooking_time=' +
            this.state.timeFilter +
            '&english=' +
            0,
            {
                method: 'GET',
            }
        )
            .then(response => response.json())
            .then(
                result => {
                    console.log(result);
                    this.setState({
                        recipes: result.recipes,
                        recipesDifficultyFilter: result.recipesDifficultyFilter,
                        recipesProductFilter: result.recipesProductFilter,
                        recipesTimeFilter: result.recipesTimeFilter,
                    });
                },
                error => {
                    console.log(error);
                    return;
                }
            )
            .finally(this.setState({ loading: false }));
    };

    getRecipe = async slug => {
        this.resetFilter();
        await this.getRecipes();
        await fetch(process.env.REACT_APP_API_ENDPOINT_URI+'api/get_recipe/' + slug, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(
                result => {
                    this.setState({
                        recipe: result.recipe,
                    });
                },
                error => {
                    console.log(error);
                    return;
                }
            )
            .finally(()=>{
                window.scrollTo(0, 0);
            });
            
    };

    resetFilter = () => {
        this.setState({
            productFilter: '',
            difficultyFilter: '',
            timeFilter: '',
        });
    };

    search = () => {
        if (this.state.searchString == '') return;
        window.location = '/resultados-de-busqueda/' + this.state.searchString;
    };

    checkEnter = key => {
        if (key.keyCode === 13) {
            this.search();
        }
    };

    capitalize = (word) => {
        return word[1].toUpperCase() + word.slice(2).toLowerCase();
    }

    render() {
        return (
            <section className='recipes_filters_section'>
                <RecipesCarousel />
                <div className='recipes_section recipes_section_margin'>
                    {this.state.loading ? <LoadScreen></LoadScreen> : null}
                    <div className='filter'>
                        <p className='section_subtitle'>FILTRA TU BÚSQUEDA</p>
                        <strong>Tipo de producto</strong>
                        {!this.state.loading ? (
                            this.state.recipesProductFilter.map((prod, i) => {
                                return (
                                    <p
                                        key={i}
                                        className='filter_item'
                                        onClick={() => {
                                            this.handleFilterEvent(
                                                prod.product_id,
                                                'product'
                                            );
                                        }}
                                    >
                                        <input
                                            className='check_input'
                                            type='checkbox'
                                            checked={
                                                this.state.productFilter ==
                                                prod.product_id
                                            }
                                        />
                                        <span className='cap-each'>{this.capitalize(prod.title.replace('',''))}</span>
                                        <span> ({prod.total})</span>
                                    </p>
                                );
                            })
                        ) : (
                            <span>Cargando filtros...</span>
                        )}
                        <div className='space_box'></div>
                        <strong>Dificultad</strong>
                        {!this.state.loading ? (
                            this.state.recipesDifficultyFilter.map(
                                (difficulty, i) => {
                                    return (
                                        <p
                                            key={i}
                                            className='filter_item'
                                            onClick={() => {
                                                this.handleFilterEvent(
                                                    difficulty.difficulty,
                                                    'difficulty'
                                                );
                                            }}
                                        >
                                            <input
                                                className='check_input'
                                                type='checkbox'
                                                checked={
                                                    this.state
                                                        .difficultyFilter ==
                                                    difficulty.difficulty
                                                }
                                                key={i}
                                            />
                                            {difficulty.difficulty}
                                            <span>({difficulty.total})</span>
                                        </p>
                                    );
                                }
                            )
                        ) : (
                            <span>Cargando filtros...</span>
                        )}
                        <div className='space_box'></div>
                        <strong>Tiempo de realización</strong>

                        {!this.state.loading ? (
                            this.state.recipesTimeFilter.map((time, i) => {
                                return (
                                    <p
                                        key={i}
                                        className='filter_item'
                                        onClick={() => {
                                            this.handleFilterEvent(
                                                time.cooking_time,
                                                'time'
                                            );
                                        }}
                                    >
                                        <input
                                            className='check_input'
                                            type='checkbox'
                                            checked={
                                                this.state.timeFilter ==
                                                time.cooking_time
                                            }
                                            key={i}
                                        />
                                        {time.cooking_time} min
                                        <span> ({time.total})</span>
                                    </p>
                                );
                            })
                        ) : (
                            <span>Cargando filtros...</span>
                        )}
                    </div>
                    <div className='recipe-grid'>
                        <div>
                            <div className='search_input_container'>
                                <input
                                    className='search_input'
                                    value={this.state.searchString}
                                    onKeyUp={e => this.checkEnter(e)}
                                    onChange={e =>
                                        this.setState({
                                            searchString: e.target.value,
                                        })
                                    }
                                    type='text'
                                    placeholder='Busca en nuestras recetas...'
                                />
                                <button
                                    className='search_btn'
                                    onClick={() => this.search()}
                                >
                                    <img
                                        className='search_icon'
                                        src={searchIcon}
                                    />
                                </button>
                            </div>
                            <div className='row'>
                                {!this.state.loading ? (
                                    this.state.recipes.map((recipe, i) => {
                                        return (
                                            <div
                                                className='col-12 my-3 my-md-0 col-md-4'
                                                key={i}
                                            >
                                                <div
                                                    className='recipe'
                                                    onClick={() => window.location.href = "/receta/" + recipe.slug}
                                                >
                                                    <div className='featuredImage'>
                                                        <img
                                                            className='recipe_img'
                                                            src={
                                                                process.env.REACT_APP_API_ENDPOINT_URI+'storage/recipes/' +
                                                                recipe.image
                                                            }
                                                        />
                                                        <p className='recipe_title'>
                                                            {recipe.title}
                                                        </p>
                                                        <p>
                                                            {recipe.description.substring(
                                                                0,
                                                                70
                                                            ) + '...'}
                                                        </p>
                                                        <div className='recipe_time_difficulty'>
                                                            <div className='diff_time_container'>
                                                                <p className='difficulty_container'>
                                                                    <img
                                                                        className='signal_icon'
                                                                        src={
                                                                            SignalIcon
                                                                        }
                                                                    />
                                                                    Dificultad
                                                                    <span className='difficulty'>
                                                                        {' '}
                                                                        {
                                                                            recipe.difficulty
                                                                        }
                                                                    </span>
                                                                </p>
                                                                <p className='difficulty_container'>
                                                                    <img
                                                                        className='hour_icon'
                                                                        src={
                                                                            HourGlassIcon
                                                                        }
                                                                    />
                                                                    <span className='difficulty'>
                                                                        {' '}
                                                                        {
                                                                            recipe.cooking_time
                                                                        }
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <span>Cargando...</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <BannerMiToqueIsadora />
                <BannerUnSuperAlimento />
            </section>
        );
    }
}

export default Recipes;
