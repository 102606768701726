import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { NavBarSocials } from '..';
import data from './data.js';
import { IsadoraFooterLeft, IsadoraLogo } from '../../assets/images';
import styles from './footer.module.scss';

const Footer = () => {
    const {
        footer,
        footerLogo,
        footerNav,
        footerNavTitle,
        footerParagraph,
        footerNavLink,
        footerImgContainer,
        footerImgContainerOverride,
        footerImg,
        footerCopyright,
    } = styles;
    const { navigation, redesSociales, copyright } = data;
    return (
        <footer className={footer}>
            <Container fluid='lg'>
                <Row className={`${footerNav} mx-auto`}>
                    <Col className='pt-1 pb-4 text-xl-center' md={3} xl={4}>
                        <img
                            src={IsadoraLogo}
                            className={`${footerLogo}`}
                            alt='Isadora'
                        />
                    </Col>
                    {navigation.map((col, index) => (
                        <Col
                            key={index}
                            md={3}
                            xl={index === 0 ? 2 : 3}
                            className={`pb-4 ${index === 1 && 'ps-xxl-4'}`}
                        >
                            <h5
                                className={`text-white text-uppercase ${footerNavTitle}`}
                            >
                                {col.title}
                            </h5>
                            <ul className='px-0'>
                                {col.links.map((link, i) => (
                                    <li key={i}>
                                        <Link
                                            className={footerNavLink}
                                            to={link.path}
                                        >
                                            {link.text}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    ))}
                    <Col md={3} xl={3}>
                        {redesSociales.map((p, i) => (
                            <p
                                className={`text-white ${footerParagraph} mb-2`}
                                key={i}
                            >
                                {p}
                            </p>
                        ))}
                        <NavBarSocials footer color={true} />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='px-0 pt-4 pt-md-0 position-relative'>
                <Row
                    className={`${footerImgContainer} ${footerImgContainerOverride} me-0`}
                >
                    <div>
                        <img
                            src={IsadoraFooterLeft}
                            className={footerImg}
                            alt='Frijoles Isadora'
                        />
                    </div>
                </Row>
                <Row className={`${footerCopyright} py-3 mx-0`}>
                    <ul className='d-flex flex-wrap justify-content-center my-0'>
                        <li className='text-white'>
                            Copyright© {new Date().getFullYear()} Isadora {'|'}
                        </li>
                        {copyright.map((item, index) => (
                            <li key={index} className='text-white'>
                                <Link
                                    to={item.path}
                                    className={`${footerNavLink} ps-1`}
                                >
                                    {item.text}
                                </Link>
                                {index === 0 && ' | '}
                            </li>
                        ))}
                    </ul>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
