import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Preloader from '../../components/Preloader';
import './styles.scss';
import { sfBolsas } from '../../assets/images';

const NewsResultsGrid = ({ cantidad, categoryId, fecha, texto }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [links, setLinks] = useState([]);
    const { pathname } = useLocation();
    

    // Obtén los parámetros de la URL
    const paramsActual = new URLSearchParams(window.location.search);

    // Obtiene el valor del parámetro 'page'
    const pageActual = paramsActual.get('page');

    const [currentPage, setCurrentPage] = useState(pageActual);


    /*Para cambiar opciones de filtrado a español*/
    const meses = {
        'enero': 'january',
        'febrero': 'february',
        'marzo': 'march',
        'abril': 'april',
        'mayo': 'may',
        'junio': 'june',
        'julio': 'july',
        'agosto': 'august',
        'septiembre': 'september',
        'octubre': 'october',
        'noviembre': 'november',
        'diciembre': 'december'
    };
    /*Expresión regular para encontrar nombres de meses en español seguidos de un guion y un año*/
    const regex = /(\benero\b|\bfebrero\b|\bmarzo\b|\babril\b|\bmayo\b|\bjunio\b|\bjulio\b|\bagosto\b|\bseptiembre\b|\boctubre\b|\bnoviembre\b|\bdiciembre\b)\s*-\s*\d{4}/gi;


    let mesAnio = fecha.replace(regex, (match) => {
        /*Extraer el nombre del mes en español*/
        const mesEspanol = match.trim().split('-')[0].toLowerCase();
        /*Verificar si el nombre del mes en español existe en el objeto de mapeo*/
        if (mesEspanol in meses) {
            /*Devolver el nombre del mes en inglés seguido del año*/
            return meses[mesEspanol] + match.trim().substring(match.indexOf('-'));
        }
        // Si no se encuentra el mes en el mapeo, devolver la cadena original
        return match;
    });
    /*Fin: Para cambiar opciones de filtrado a español*/


    
    const BASE_URL = process.env.REACT_APP_API_BASE_URL_NEWS;
    const endpoint = '/api/news/'+cantidad+'/'+categoryId+'/'+mesAnio+'/'+texto+'?page='+pageActual;




    const handlePageClick = (pageNumber) => {

      setCurrentPage(pageNumber);
    };



    const getNews = async () => {
        setLoading(true);

        try {
          const res = await fetch(BASE_URL + endpoint, {
            method: 'GET',
          });

          const data = await res.json();
          setData(data);

          if (data.news) {
            setLinks(data.news.links || []);
          }
         
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

    useEffect(() => {
        getNews();
    }, [pathname, pathname, categoryId, fecha, texto, pageActual]);

    let paginacionHTML = null;
    let cantBotonesPag = 0;


    if (!loading && data && data.news && data.news.data.length > 0) {
      

        const currentDomain = window.location.origin;

        const paginacionItems = links
          .filter((link) => link.url !== null)
          .map((link, i) => {
            let urlString = link.url;
            let pageValue = 1;
            cantBotonesPag++;

            let regex = /[?&]page=(\d+)/;
            let match = urlString.match(regex);

            if (match) {
              pageValue = match[1];
            }


            let numero = parseInt(link.label, 10);
            let txtboton = '';
            let pagActiva = 1;
            let classActiva = '';

            if(!isNaN(numero) && Number.isInteger(numero)){
              txtboton = numero;

              if(pagActiva == numero){
                classActiva = 'activa';
              }

            }
            else{
              if(link.label.toLowerCase().includes('next')){
                  txtboton = '>>';
              }
              else if(link.label.toLowerCase().includes('prev')){
                  txtboton = '<<';
              }
              else{
                txtboton = '';
              }
              
            }

            return (
              <Link className={`alx-link-paginado ${currentPage === pageValue ? 'activa' : ''}`}
                to={`/noticias/resultados/${categoryId}/${fecha}/${texto}?page=${pageValue}`}
                key={i} 
                onClick={() => handlePageClick(pageValue)}
              >
                {txtboton}
              </Link>
            );
          });

        if(cantBotonesPag > 1){
          paginacionHTML = <div className="alx-pagination">{paginacionItems}</div>;
        }  
    }

    return (
        <div className='row'>

            {data?.news.data ? (
                (data?.news.data.length == 0)
                    ?
                    <>
                      <div className="text-center alx-no-results">
                        <div className="font-gotham-bold text-brownIs alx-color-cafe">No existen resultados, <br/>Te sugerimos ver nuestros productos, <Link className='alx-color-naranja' to={'/productos'} >click here.</Link>
                        </div>
                        <br/>
                        <img src={sfBolsas} alt='' className='alx-no-results-img' />
                        <br/><br/>
                      </div>                 
                    </>
                    :
                    <>
                        {data.news.data.map((noticia, i) => {
                            return (
                                <div className="col-xs-12 col-md-4" 
                                    key={noticia.id}
                                > 
                                    <Link to={'/noticias/' + noticia.slug} 
                                        onClick={() => window.scrollTo(0, 0)} 
                                        className='alx-receta-min'>
                                        <img
                                            src={
                                                    BASE_URL +
                                                    '/uploads/noticias/' +
                                                    noticia.imagen_mobile


                                                    
                                                }
                                            alt=""
                                            className='w-auto img-fluid'
                                        />
                                        <div className='alx-color-cafe'>
                                            {noticia.titulo}
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}

                        <div className="col-xs-12" id="alx-paginado">
                          {paginacionHTML}
                        </div>
                    </>
            ) : (
                <Preloader />
            )}

        </div>
    );
};

export default NewsResultsGrid;