import React from 'react';
import './style.css';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <>
                <div className='container'>
                    <div className='row justify-content-center privacy-policy-row'>
                        <div className='col-sm-12 col-md-12 col-lg-7 col-xl-7'>
                            <section className='privacy-notice'>
                                <h3 className='notice-title'>
                                    AVISO DE PRIVACIDAD
                                </h3>
                                <p>
                                    PRODUCTOS VERDE VALLE, S.A. de C.V., en lo
                                    sucesivo Productos Verde Valle. emite el
                                    presente Aviso de Privacidad con la
                                    finalidad de dar cabal cumplimiento a lo
                                    establecido en la Ley Federal de Protección
                                    de Datos Personales en Posesión de
                                    Particulares, su reglamento y demás
                                    normativas que resulten aplicables.
                                </p>
                            </section>
                            <section className='policy'>
                                <h3 className='policy-title'>
                                    A) RESPONSABLE DE LA INFORMACIÓN
                                </h3>
                                <p>
                                    PRODUCTOS VERDE VALLE, S.A. de C.V. y/o de
                                    cualquier afiliada y/o cualquier subsidiaria
                                    del Grupo antes mencionado, en lo sucesivo
                                    Productos Verde Valle -titular y/o
                                    licenciataria autorizada de, entre otras,
                                    las marcas VERDE VALLE®, ISADORA®, HELENA’S
                                    HUMMUS ®, SALSAS SONIA®, GRANOLAS BRANLI®,
                                    CRUJINOLA®, con domicilio en Av. Vallarta
                                    5683, Colonia Santa María del Pueblito, C.P.
                                    45018 en Zapopan, Jalisco, México, teléfono
                                    800-84-70-088, correo electrónico{' '}
                                    <a
                                        href='mailto:atencionaclientes@verdevalle.com.mx'
                                        className='text-black'
                                    >
                                        atencionaclientes@verdevalle.com.mx
                                    </a>
                                    , es el responsable del uso y protección de
                                    datos personales, y al respecto le informa
                                    lo siguiente:
                                </p>
                                <p>
                                    La información proporcionada por los
                                    Titulares, mediante cualquier medio de
                                    comunicación, estará sujeta a este Aviso de
                                    Privacidad y a los Términos que rijan la
                                    relación que exista con ellos.
                                </p>
                                <p>
                                    SI NO ESTÁ DE ACUERDO CON ESTE AVISO DE
                                    PRIVACIDAD Y/O CON CUALQUIERA DE SUS
                                    APARTADOS POR FAVOR OMITA COMPARTIR
                                    INFORMACIÓN DE CUALQUIER TIPO CON PRODUCTOS
                                    VERDE VALLE,
                                </p>
                                <p className='fw-bold'>Campo de Aplicación</p>
                                <p>
                                    Este Aviso de Privacidad es aplicable en el
                                    territorio que comprende la República
                                    Mexicana únicamente y será aplicable a los
                                    Titulares que proporcionen información a
                                    Productos Verde Valle.
                                </p>
                            </section>
                            <section className='policy'>
                                <h3 className='policy-title'>
                                    B) DATOS PERSONALES
                                </h3>
                                <p>
                                    Para llevar a cabo las finalidades descritas
                                    en el presente Aviso de Privacidad
                                    utilizaremos datos personales de
                                    identificación, contacto de las personas que
                                    se localizan en los Estados Unidos Mexicanos
                                    y/o en cualquier otro lugar. No aceptamos la
                                    jurisdicción o legislación diferente de la
                                    que regula en el territorio de la República
                                    Mexicana. Adicionalmente, pudiéramos obtener
                                    información individualizada de fuentes de
                                    terceros que a su vez se obligan a cumplir
                                    con la legislación aplicable en materia de
                                    privacidad y se agrega a la información de
                                    usted que se mantiene en la Base de Datos.
                                </p>
                                <p>
                                    Además de los datos personales mencionados
                                    anteriormente, para las finalidades
                                    informadas en el presente Aviso de
                                    Privacidad, utilizaremos los siguientes
                                    datos personales considerados como
                                    sensibles, que requieren de especial
                                    protección: origen racial o étnico, estado
                                    de salud presente o futuro, información
                                    genética, creencias religiosas, filosóficas
                                    y morales, recordando que por el regla
                                    general el tratamiento de datos personales
                                    sensibles exige la obtención del
                                    consentimiento expreso y por escrito del
                                    Titular a través de su firma autógrafa,
                                    electrónica o cualquier otro mecanismo que
                                    para tal efecto se establezca.
                                </p>
                            </section>
                            <section className='policy'>
                                <h3 className='policy-title'>
                                    C) FINALIDADES PRIMARIAS
                                </h3>
                                <p>
                                    Los datos personales proporcionados por
                                    usted, incluso aquellos de carácter
                                    sensible, serán utilizados para todos los
                                    fines vinculados con la relación jurídica
                                    y/o contractual que celebremos con usted.
                                    Considerados los efectos de dicha relación
                                    jurídica como la Finalidad Primaria.
                                </p>
                            </section>
                            <section className='policy'>
                                <h3 className='policy-title'>
                                    D) FINALIDADES SECUNDARIAS
                                </h3>
                                <p>
                                    Los datos personales que recabamos de usted,
                                    los utilizaremos para las siguientes
                                    finalidades, que no son necesarias para el
                                    servicio solicitado, pero que nos permite y
                                    facilita brindarle una mejor atención:
                                </p>
                                <ol className='px-3'>
                                    <li>
                                        Usar la información del Titular para
                                        informar sobre nuestras actividades,
                                        servicios, productos, Programas y/o
                                        Promociones, que consideramos puedan ser
                                        del interés de los Titulares, derivado
                                        del tipo de relación o vínculo que
                                        exista con cada uno.
                                    </li>
                                    <li>
                                        Incluir información de los Titulares
                                        recolectada de otras fuentes que
                                        consideramos confiables para
                                        identificarlos con mayor precisión y así
                                        poder actualizar la información que
                                        almacenamos y proporcionamos a otras
                                        partes cuando la información cambia,
                                        como cambios en la dirección; nombre,
                                        teléfono, correo electrónico, etc.
                                    </li>
                                    <li>
                                        Proporcionar a otras partes el nombre,
                                        dirección, número telefónico e
                                        información de actividades, sobre los
                                        Titulares que tengan vínculo con
                                        nosotros para que sean usados
                                        estrictamente apegados a los fines para
                                        los cuales fueron proporcionados y en
                                        términos del presente Aviso de
                                        Privacidad y de la legislación
                                        aplicable.
                                    </li>
                                    <li>
                                        Crear y utilizar información agregada
                                        del Titular que no sea individualmente
                                        identificable para entender más sobre
                                        las características comunes e intereses
                                        de los Titulares; así como crear
                                        estadísticas, opiniones y estrategias
                                        con base en los datos disgregados del
                                        Titular para entender comportamiento del
                                        mercado ya sea mediante estudios propios
                                        o de terceros a quienes se hubieran
                                        transferido los datos del Titular para
                                        su tratamiento en términos de este Aviso
                                        de Privacidad.
                                    </li>
                                    <li>
                                        Usar información del Titular para poder
                                        ejecutar o hacer valer algún contrato
                                        con nosotros o cualesquiera Términos de
                                        relación o vínculo entre nosotros, y/o
                                        los términos de Uso de nuestros Sitios,
                                        o proporcionar acceso o dar a conocer
                                        información que actuando de buena fe
                                        consideremos que es necesaria para
                                        cumplir con la legislación aplicable;
                                    </li>
                                    <li>
                                        Transmitir Información del Titular a
                                        otra parte que adquiera el negocio o
                                        acciones al cual la información se
                                        relacione;
                                    </li>
                                    <li>
                                        Transmisión y tratamiento de la
                                        Información del Titular a nuestras
                                        afiliadas y subcontratistas que
                                        administren cualquiera de nuestras
                                        actividades a nuestro nombre o tal como
                                        consideremos necesario para mantener,
                                        prestar y mejorar los servicios.
                                    </li>
                                    <li>
                                        En caso de que no desee que sus datos
                                        personales sean tratados para estos
                                        fines, usted puede manifestar su
                                        negativa dentro de los cinco días
                                        siguientes a PRODUCTOS VERDE VALLE a
                                        través correo electrónico señalado en el
                                        presente Aviso de Privacidad. La
                                        negativa para el uso de sus datos
                                        personales para estas finalidades no
                                        podrá ser un motivo para que le neguemos
                                        los servicios y productos que solicita o
                                        contrata con nosotros.
                                    </li>
                                </ol>
                            </section>
                            <section className='policy'>
                                <h3 className='policy-title'>E) SEGURIDAD</h3>
                                <p>
                                    Solamente recolectamos información que
                                    estimamos razonablemente necesaria para
                                    servir con los intereses legítimos de
                                    nuestro negocio y para cumplir con nuestras
                                    obligaciones legales. Protegemos a los
                                    Titulares por cuanto, a su información
                                    divulgada, utilizando estándares de
                                    protección vigentes en la industria y de
                                    conformidad con la Ley Federal de Protección
                                    de Datos Personales en Posesión de los
                                    Particulares y su Reglamento. Con
                                    independencia que no exista una seguridad
                                    garantizada ya sea en Internet o fuera del
                                    mismo, nosotros realizamos los esfuerzos
                                    comercialmente razonables para lograr que la
                                    recolección de información que realicemos se
                                    haga de conformidad con toda la legislación
                                    aplicable.
                                </p>
                                <p>
                                    Por el momento, cada medio de recolección de
                                    información incluyendo nuestros sitios,
                                    utilizan una variedad de medidas de
                                    seguridad destinadas a proteger la
                                    información, para evitar el uso no
                                    autorizado de usuarios ya sea dentro o fuera
                                    de la compañía, incluyendo medidas de
                                    seguridad administrativas, técnicas y
                                    físicas establecidas en la ley de la
                                    materia. Así se protege la pérdida, daño,
                                    mal uso, intercepción o sabotaje de
                                    información confidencial, tales como números
                                    confidenciales, de solicitudes en línea e
                                    información financiera.
                                </p>
                                <p>
                                    Nuestra práctica en los sistemas de
                                    comunicación, software y base de datos
                                    físicas y electrónicas, están diseñados para
                                    ayudarnos en mantener la autenticidad,
                                    integridad y confidencialidad de dicha
                                    Información. Es importante señalar que con
                                    independencia que utilicemos todos los
                                    medios necesarios para mantener la seguridad
                                    de la información cuando esta sea
                                    transmitida a través de terceros
                                    (proveedores de servicios), no garantizamos
                                    la seguridad de la Información en dichas
                                    transmisiones, por cualquier medio o forma.
                                </p>
                                <p>Lo que no haremos con la Información</p>
                                <ol className='px-3'>
                                    <li>
                                        Vender o rentar la información a otras
                                        partes fuera de las sociedades bajo el
                                        control y/o relacionadas con PRODUCTOS
                                        VERDE VALLE (sin incluir contratos
                                        celebrados mientras estaban relacionadas
                                        con PRODUCTOS VERDE VALLE y que
                                        posteriormente salieron del control de
                                        PRODUCTOS VERDE VALLE), o permitir a
                                        nuestras afiliadas vender o rentar la
                                        Información a terceros fuera del control
                                        de PRODUCTOS VERDE VALLE, de empresas
                                        presentes o pasadas.
                                    </li>
                                    <li>
                                        Actuar como una agencia de evaluación de
                                        servicios y actividades con Titulares o
                                        proporcionar algún tipo de información
                                        relacionada con la posición económica,
                                        poder adquisitivo, forma de ser,
                                        reputación, características personales o
                                        modo de vivir del Titular, a cualesquier
                                        agencias de evaluación de servicios.
                                    </li>
                                </ol>
                            </section>
                            <section className='policy'>
                                <h3 className='policy-title'>
                                    F) TRANSFERENCIAS
                                </h3>
                                <p>
                                    Le informamos que sus datos personales
                                    podrán ser compartidos dentro y fuera del
                                    país con nuestras Afiliadas, Subcontratistas
                                    y/o Proveedores para mantener, prestar y
                                    mejorar los servicios.
                                </p>
                                <p>
                                    Salvo disposición en contrario por ley o por
                                    nuestras obligaciones contractuales,
                                    podremos dar a conocer información personal
                                    si se requiere por ley, orden judicial, o
                                    por requerimiento de una autoridad
                                    gubernamental, o de buena fe si consideramos
                                    que la divulgación de dicha información es
                                    necesaria o aconsejable. Las situaciones
                                    podrán incluir: realizar, mantener o tratar
                                    de hacer cumplir los contratos con nuestros
                                    clientes o cualquier Titular para proteger
                                    los derechos o propiedades de nuestras
                                    afiliadas y socios, los Titulares y otros, o
                                    cuando tengamos razón para creer que
                                    divulgar la información es necesaria para
                                    identificar, contactar o ejercer alguna
                                    acción legal en contra de cualquiera que
                                    cause o pretenda causar interferencia con la
                                    titularidad de nuestros derechos o los pueda
                                    lesionar, o de los productos o nuestras
                                    Marcas, ya sea de forma intencional o de
                                    forma diversa, o cuando cualquier otra
                                    persona pudiere ser dañada por dichas
                                    actividades.
                                </p>
                                <p>
                                    En caso de tratarse de una transferencia de
                                    datos en las que se requiera su
                                    consentimiento expreso, lo recabaremos con
                                    anterioridad a la transferencia.
                                </p>
                            </section>
                            <section className='policy'>
                                <h3 className='policy-title'>
                                    G) ACCESO, RECTIFICACIÓN, CANCELACIÓN Y
                                    OPOSICIÓN
                                </h3>
                                <p>
                                    Usted tiene derecho a conocer qué datos
                                    personales tenemos de usted, para qué los
                                    utilizamos y las condiciones del uso que les
                                    damos (acceso). Asimismo, es su derecho
                                    solicitar la corrección de la información
                                    personal en caso de que esté desactualizada,
                                    sea inexacta o incompleta (rectificación);
                                    que la eliminemos de nuestros registros o
                                    bases de datos cuando considere que la misma
                                    no está siendo utilizada conforme a los
                                    principios, deberes y obligaciones previstas
                                    en la normativa (cancelación); así como
                                    oponerse al uso de sus datos personales para
                                    fines específicos (oposición). Estos
                                    derechos se conocen como derechos “ARCO”.
                                    Para el ejercicio de cualquiera de los
                                    derechos “ARCO” usted deberá presentar la
                                    solicitud contactando al Departamento de
                                    Atención a Clientes a través de: correo
                                    electrónico{' '}
                                    <a
                                        href='mailto:atencionaclientes@verdevalle.com.mx'
                                        className='text-black'
                                    >
                                        atencionaclientes@verdevalle.com.mx
                                    </a>
                                    , señalando: (i) el nombre del Titular y
                                    domicilio u otro medio para comunicarle la
                                    respuesta a su solicitud, (ii) los
                                    documentos que acrediten la identidad o, en
                                    su caso, la representación legal del
                                    Titular, (iii) la descripción clara y
                                    precisa de los datos personales respecto de
                                    los que se busca ejercer alguno de los
                                    derechos antes mencionados, y (iv) cualquier
                                    otro elemento o documento que facilite la
                                    localización de los datos personales.
                                </p>
                                <p>
                                    Nosotros nos comunicaremos con el Titular de
                                    la información, en un plazo máximo de 20
                                    días, contados desde la fecha en que se
                                    recibió la solicitud de acceso,
                                    rectificación, cancelación u oposición, para
                                    señalar la determinación adoptada, respecto
                                    de su solicitud, a efecto de que, si resulta
                                    procedente, se haga efectiva la misma dentro
                                    de los 15 días siguientes a la fecha en que
                                    se comunica la respuesta.
                                </p>
                                <p>
                                    Los plazos antes referidos podrán ser
                                    ampliados una sola vez por un periodo igual,
                                    siempre y cuando así lo justifiquen las
                                    circunstancias del caso.
                                </p>
                                <p>
                                    Sin embargo, continuaremos enviándole
                                    correos electrónicos relacionados con el
                                    vínculo que se encuentre vigente entre
                                    nosotros, hasta la conclusión de los mismos.
                                    Los Titulares podrán excluirse de toda la
                                    información de contacto, incluyendo correo
                                    electrónico, mensajería directa, facsímil y
                                    teléfono. Nosotros mantenemos listas de “no
                                    llamar” y “no enviar correspondencia” Las
                                    solicitudes para incluir su información en
                                    dichas listas se resuelven dentro del plazo
                                    máximo de 20 días hábiles siguientes a su
                                    recepción, si resulta procedente la
                                    solicitud, la misma se hará efectiva dentro
                                    de los 15 días siguientes a la fecha en que
                                    se comunica la respuesta. Ambos periodos son
                                    prorrogables por el mismo tiempo. Cualquier
                                    Cliente podrá optar por no recibir cierta
                                    información, contactándonos a través del
                                    correo electrónico, teléfono o dirección
                                    señalados como identificación de PRODUCTOS
                                    VERDE VALLE al inicio del presente Aviso de
                                    Privacidad.
                                </p>
                                <p>
                                    Usted puede revocar el consentimiento que,
                                    en su caso, nos haya otorgado para el
                                    trámite de sus datos personales. Sin
                                    embargo, es importante que tenga en cuenta
                                    que no en todos los casos podremos atender
                                    su solicitud o concluir el uso de forma
                                    inmediata, ya que es posible que por alguna
                                    obligación legal requiramos seguir tratando
                                    sus datos personales.
                                </p>
                                <p>
                                    Asimismo, usted deberá considerar que, para
                                    los fines principales, la revocación de su
                                    consentimiento implicará que no le podamos
                                    seguir prestando el servicio que nos
                                    solicitó o la conclusión de su relación con
                                    nosotros.
                                </p>
                                <p>
                                    Con objeto de que usted pueda limitar el uso
                                    y divulgación de su información personal, le
                                    ofrecemos el siguiente medio:
                                </p>
                                <p>
                                    Su inscripción en el Registro Público para
                                    evitar publicidad que está a cargo de la
                                    Procuraduría Federal del Consumidor, con la
                                    finalidad de que sus datos personales no
                                    sean utilizados para recibir publicidad o
                                    promociones de empresas de bienes o
                                    servicios. Para más información sobre este
                                    registro, usted puede consultar el portal de
                                    internet de la PROFECO o bien ponerse en
                                    contacto con esta institución.
                                </p>
                            </section>
                            <section className='policy'>
                                <h3 className='policy-title'>
                                    H) COOKIES, WEB BEACONS Y PIXELES INVISIBLES
                                </h3>
                                <p>
                                    Le informamos que en nuestra página de
                                    internet podremos utilizar “cookies”, “web
                                    Beacons” y Pixeles Invisibles a través de
                                    las cuales es posible monitorear su
                                    comportamiento como usuario de internet,
                                    brindarle un mejor servicio y experiencia de
                                    usuario al navegar en nuestra página, así
                                    como ofrecerle nuevos productos y servicios
                                    basados en sus preferencias.
                                </p>
                                <p>
                                    Los datos personales que obtenemos de estas
                                    tecnologías de rastreo son los siguientes:
                                    horario de navegación, tiempo de navegación
                                    en nuestra página de internet, secciones
                                    consultadas, y páginas de internet accedidas
                                    previo a la nuestra.
                                </p>
                                <p>
                                    No utilizamos cookies para almacenar
                                    contraseñas o información de claves de
                                    acceso. Las cookies no nos muestran su
                                    identidad salvo que usted hubiere escogido
                                    proporcionarla.
                                </p>
                                <p>
                                    Su buscador podrá programarse para que le
                                    notifique cuando una cookie sea instalada en
                                    su buscador, para que pueda declinar o
                                    borrar las cookies que hubieren sido
                                    instaladas en su buscador. Algunas funciones
                                    de nuestros sitios podrán no funcionar o
                                    funcionar lentamente si se rechaza una
                                    cookie.
                                </p>
                                <p>
                                    Nuestro sitio pudiera utilizar los servicios
                                    de terceros como servidor y servicios de
                                    hospedaje de nuestros promocionales o bien
                                    hacer uso de medios propios.
                                </p>
                                <p>
                                    En caso de terceros, éstos podrán colocar
                                    cookies en su computadora si usted le da
                                    “click” o pulsa sobre la publicidad.
                                </p>
                                <p>
                                    Las cookies de terceros se utilizan para
                                    rastrear si el sitio se acceso del
                                    promocional. Las cookies que se generan de
                                    los promocionales no contienen información
                                    personal. Nosotros no controlamos estas
                                    cookies y estas podrán no seguir las reglas
                                    que hemos establecido para nuestras cookies.
                                </p>
                                <p>
                                    Nosotros y nuestros servidores de publicidad
                                    podremos utilizar pixeles invisibles, mejor
                                    conocidos como contadores de visitantes, en
                                    nuestro sitio para contar cuantas personas
                                    visitan ciertas páginas web. La información
                                    recolectada de pixeles invisibles se utiliza
                                    y es reportada en la información agregada
                                    sin la necesidad de utilizar información
                                    personal.
                                </p>
                                <p>
                                    Esta información podrá ser utilizada para
                                    mejorar los sitios, la publicidad y su
                                    contenido, así como para repuntar nuestra
                                    publicidad en nuestro sitio y el de terceros
                                    en Internet. Para más información sobre
                                    nuestro servidor de publicidad o para
                                    conocer las opciones para que no se use su
                                    información personal para enviarle
                                    promociones favor de contactar a
                                </p>
                                <p>
                                    <a href='mailto:atencionaclientes@verdevalle.com.mx' className='text-black'>
                                        atencionaclientes@verdevalle.com.mx
                                    </a>
                                </p>
                                <p>
                                    En nuestros sitios no recolectamos
                                    información personalmente identificable de
                                    los Titulares salvo que nos sea
                                    proporcionada de manera voluntaria y con
                                    pleno conocimiento. Cuando usted accede a
                                    nuestros Sitios, nosotros solicitaremos su
                                    autorización para capturar información como
                                    el nombre, dirección, número telefónico,
                                    edad, fecha de nacimiento, lugar de
                                    nacimiento, y correo electrónico.
                                </p>
                                <p>
                                    El objetivo principal de capturar su correo
                                    electrónico es enviarle datos de PRODUCTOS
                                    VERDE VALLE y sus productos y/o Marcas, y/o
                                    intercambiar información relacionada al
                                    vínculo comercial o de cualquier otra índole
                                    existente entre nosotros, en adición a las
                                    Promociones y/o Programas que eventualmente
                                    puedan existir. Como muchos otros sitios de
                                    Internet, nosotros recolectamos de manera
                                    automática información no personal
                                    relacionada con los Titulares, tales como
                                    información relacionada con información del
                                    software de los mismos (por ejemplo
                                    direcciones IP, versiones de buscadores y
                                    sistemas operativos) e información agregada
                                    (por ejemplo, el número de páginas a que se
                                    acceda) a efecto de analizar el tráfico en
                                    la Web, y las tendencias de uso, y de esta
                                    manera permitirnos definir el contenido y
                                    servicios para ajustarnos a las necesidades
                                    de la relación sostenida con cada uno de los
                                    Titulares. La información de esta
                                    naturaleza, no se asociará a su identidad ni
                                    a su información personal.
                                </p>
                                <p>
                                    Nuestros sitios de Internet tienen
                                    hipervínculos y/o páginas relacionadas con
                                    el sitio, que conducen al Titular a otros
                                    Sitios, algunos de los cuales no se
                                    encuentran afiliados o controlados por
                                    nosotros. Una vez que usted abandona
                                    nuestros Sitios, cada sitio nuevo que visite
                                    podrá tener su propio aviso de privacidad y
                                    términos de uso. Su interacción con dichos
                                    sitios no estará a lo dispuesto por este
                                    Aviso de Privacidad y los términos de uso.
                                    Por lo tanto, el uso que realice de dichos
                                    sitios de terceros es bajo su propio riesgo.
                                </p>
                                <p>
                                    Tratándose del uso de los sitios, nos
                                    esforzamos para crear y mantener las
                                    prácticas de seguridad técnica,
                                    administrativa y física, así como otros
                                    sistemas apropiados de protección, para
                                    mantener el control que tenemos de la
                                    Información, que la misma sea usada tal como
                                    se autoriza y evitar que la misma sea
                                    alterada o destruida.
                                </p>
                            </section>
                            <section className='policy'>
                                <h3 className='policy-title'>
                                    I) DISPONIBILIDAD AVISO DE PRIVACIDAD Y
                                    MODIFICACIONES
                                </h3>
                                <p>
                                    El Aviso de Privacidad que se encuentre
                                    vigente al momento en que se comparte la
                                    información, aplicará para dicho
                                    intercambio. No obstante, podremos cambiar o
                                    dar por vencida la vigencia de este Aviso de
                                    Privacidad, en cuyo caso le haremos saber a
                                    través de: (i) anuncios visibles en nuestros
                                    establecimientos o en centros de atención a
                                    clientes; (ii) trípticos o folletos
                                    disponibles en nuestros establecimientos o
                                    centros de atención a clientes; (iii) en
                                    nuestros sitios de internet y/o (iv)
                                    haciéndosela llegar a través de su correo
                                    electrónico y le proporcionaremos la
                                    posibilidad de rechazar cualquier uso, o uso
                                    anticipado de la Información previsto en el
                                    nuevo Aviso de Privacidad. Favor de revisar
                                    nuestro Aviso de Privacidad cada vez que
                                    comparta información o con mayor frecuencia
                                    si usted está preocupado por el uso que se
                                    le dará a su Información.
                                </p>
                                <p>Confidencialidad de la Información</p>
                                <p>
                                    Cuando se solicite Información Personal,
                                    usted sólo compartirá la información con
                                    nosotros, salvo indicación en contrario.
                                </p>
                                <p>
                                    Si la Información Personal de cualquier
                                    Titular se comparte con socios de negocios o
                                    patrocinadores, el Titular será notificado
                                    antes de que dicha información sea
                                    recolectada o transferida. Si el Titular no
                                    desea que dicha información sea compartida,
                                    podrá abstenerse de usar un servicio en
                                    específico o de participar en ciertas
                                    promociones, actividades y concursos.
                                </p>
                                <p>
                                    PRODUCTOS VERDE VALLE no será responsable
                                    por la confidencialidad de cualquier
                                    información adicional proporcionada por el
                                    Titular, incluyendo la información
                                    suministrada a través de boletines o foros
                                    de plática, ni será responsable de la
                                    información que se obtenga a través de las
                                    cookies descritos en este Aviso de
                                    Privacidad.
                                </p>
                                <p>Contacto con el Titular</p>
                                <p>
                                    Cuando un Titular llama a nuestros centros
                                    de atención o vía Internet, facsímil u otras
                                    formas de contacto a PRODUCTOS VERDE VALLE
                                    para promociones o consultas relacionadas
                                    con el vínculo existente entre PRODUCTOS
                                    VERDE VALLE y el Titular, necesitaremos
                                    cierta información tal como el nombre,
                                    dirección postal, dirección de correo
                                    electrónico y teléfono para responder a la
                                    petición correspondiente y/o registrarse en
                                    la Promoción respectiva. El Titular en todo
                                    tiempo deberá preguntar y registrar el
                                    número de solicitud de información o
                                    confirmación de registro al momento de
                                    realizar, cambiar o cancelar dicha solicitud
                                    y/o registro. La información recolectada
                                    como parte del proceso de registro se
                                    utilizará tal como este Aviso de Privacidad
                                    describe.
                                </p>
                                <p>
                                    Nosotros solicitaremos a los Titulares que
                                    proporcionen su correo electrónico cuando
                                    realicen un registro con nosotros para
                                    cualquier tipo de actividad y por cualquier
                                    medio. El objetivo principal de solicitar
                                    sus correos electrónicos al realizar un
                                    registro es para efectos de enviare una
                                    confirmación del registro realizado.
                                    Asimismo, podremos recolectar los correos
                                    electrónicos de Titulares y compartirlos con
                                    algunos de nuestros proveedores para efecto
                                    de realizar investigaciones y encuestas. Los
                                    Titulares, siempre tendrán la opción de
                                    darse de baja de nuestra Base de Datos de
                                    que se proporcione dicha información a
                                    terceros o respecto de comunicaciones
                                    futuras; no obstante, lo anterior,
                                    continuaremos enviando comunicaciones para
                                    confirmación de actividades en los que ya se
                                    encuentren inscritos los Titulares, derivado
                                    de un vínculo vigente.
                                </p>
                                <p>
                                    Nosotros, intentamos limitar el envío de
                                    comunicaciones (fuera de correos, avisos y
                                    encuestas electrónicas) a los Titulares que
                                    están de acuerdo en recibirlos y/o los
                                    titulares que han permitido a otras partes
                                    compartir su correo electrónico para efectos
                                    de recibir comunicaciones diversas. En
                                    cualquier tiempo el Titular podrá rechazar
                                    recibir comunicaciones mediante notificación
                                    a nosotros.
                                </p>
                                <p>
                                    Actualmente utilizamos servicios de
                                    proveedores para enviar comunicaciones. El
                                    proveedor tiene prohibido utilizar los datos
                                    de contacto del Titular, de otra manera
                                    diferente al envío de comunicaciones
                                    relacionadas con el envío de información
                                    derivada del vínculo existente entre
                                    nosotros y el Titular. En el entendido que
                                    dichos proveedores conocen este Aviso de
                                    Privacidad.
                                </p>
                                <p>
                                    Ocasionalmente podremos llevar a cabo
                                    sorteos o concursos. Nosotros le
                                    solicitaremos al Titular que desee
                                    inscribirse en el sorteo o concurso que nos
                                    proporcione su información de contacto
                                    (tales como nombre, dirección de correo
                                    electrónico, dirección postal, nombre de
                                    usuario, contraseña, número de teléfono fijo
                                    o celular, fecha de nacimiento, lugar de
                                    nacimiento, edad, información sobre el
                                    consumo de nuestros productos). Si un
                                    Titular participa en un sorteo o concurso,
                                    su información de contacto podrá ser
                                    utilizada para localizarlo con relación a
                                    los sorteos o concursos, y para efectos de
                                    promociones, mercadotecnia y del negocio.
                                    Todo tipo de solicitudes para los sorteos o
                                    concursos preverá una opción para que los
                                    participantes puedan rechazar cualquier tipo
                                    de comunicación por parte del organizador de
                                    sorteos o concursos que no esté relacionada
                                    con la entrega de premios a los ganadores
                                    del sorteo o concurso respectivo.
                                </p>
                                <p>
                                    Asimismo, los Titulares tienen la opción de
                                    rechazar estas comunicaciones. De tiempo en
                                    tiempo, podremos realizar una investigación
                                    o encuestas a través de cualquier medio (ya
                                    sea en línea o no). Asimismo, podremos
                                    encomendar a otra parte que lleve a cabo
                                    dichas encuestas en nuestro nombre. Todas
                                    las respuestas a las encuestas son
                                    voluntarias, y la información recolectada se
                                    usará únicamente para fines de investigación
                                    y de registro para poder darle un mejor
                                    servicio, aprendiendo un poco más sobre sus
                                    necesidades y la calidad de nuestras
                                    actividades. Podremos contactar a los
                                    Titulares para cuestionarlos o encuestarlos
                                    sobre su experiencia con alguna actividad o
                                    respecto de las mejoras necesarias para
                                    llamar la atención para futuros negocios.
                                    Las respuestas a las encuestas podrán
                                    utilizarse también para determinar la
                                    eficacia de nuestros servicios, sitios, de
                                    varios tipos de comunicaciones, campañas
                                    publicitarias y/o actividades promocionales.
                                </p>
                                <p>
                                    Si un Titular participa en una encuesta, la
                                    información proporcionada por el Titular
                                    será utilizada con aquella de otros
                                    participantes. Podremos compartir
                                    información anónima e información agregada
                                    para fines de investigación y análisis.
                                </p>
                            </section>
                            <section className='policy'>
                                <h3 className='policy-title'>
                                    J) DATOS DE CONTACTO
                                </h3>
                                <p>
                                    Para cualquier asunto relacionado con el
                                    presente Aviso de Privacidad y en lo
                                    relativo a sus datos personales puede
                                    comunicarse con domicilio en Av. Vallarta
                                    5683, Colonia Santa María del Pueblito, CP
                                    45018 en Zapopan, Jalisco, México, teléfono
                                    01-800-84-70-088, correo electrónico{' '}
                                    <a href='mailto:atencionaclientes@verdevalle.com.mx' className='text-black'>
                                        atencionaclientes@verdevalle.com.mx
                                    </a>{' '}
                                    en Departamento de Atención a Clientes.
                                </p>
                                <p>
                                    En caso de alguna inquietud respecto del
                                    tratamiento de datos personales, puede
                                    consultar al Instituto Federal de Acceso a
                                    la Información (IFAI) en{' '}
                                    <a href='www.ifai.org.mx' className='text-black'>
                                        www.ifai.org.mx
                                    </a>
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PrivacyPolicy;
