import './styles.css';

const Preloader = props => {
    return (
        <section
            id='productsSection'
            style={{
                height: '50vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div className='lds-ring'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <p className='productTitle'>Loading...</p>
        </section>
    );
};

export default Preloader;
