import React from 'react';
import "./RecipesCarousel.css"
import { RecipesSliderImg } from "../../assets/images/index";

class RecipesCarousel extends React.Component {


    render() {
        
        return (
            <section className='hero_slides' >
                <div className='hero_content' >
                    <h1>Prepara recetas</h1>
                    <h1>increíbles</h1>

                    <p className='mt-5'>Para una tarde de películas, una comida familiar</p>
                    <p>o para el lunch de todos los días. ¡Los frijoles</p>
                    <p>Isadora son perfectos para toda ocasión!</p>
                    <p>Descúbre los ricos y sencillos platillos que </p>
                    <p>puedes preparar para cumplir todos tus antojos.</p>
                </div>
                <img className='hero_img' src={RecipesSliderImg} />
            </section>
        )
    }
}

export default RecipesCarousel;