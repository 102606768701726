import { Row, Col } from 'react-bootstrap';
import { florFrijolesIsadora } from '../../assets/media';
import styles from './BackGroundImageHero.module.scss';

const ContentHeroFrijolSuperAlimento = () => {
    const { heroMainTitle, heroMainImg, heroSecondaryTitle } = styles;
    return (
        <>
            <Row>
                <Col className='text-center'>
                    <img
                        src={florFrijolesIsadora}
                        className={`${heroMainImg} mb-3`}
                        alt='Flor ornamento'
                    />
                    <h1 className={`${heroMainTitle} mx-auto mb-4 text-white`}>
                        Frijol, un súper alimento
                    </h1>
                    <h3 className={`${heroSecondaryTitle} text-white pt-2`}>
                        · RICOS Y SALUDABLES ·
                    </h3>
                </Col>
            </Row>
        </>
    );
};

export default ContentHeroFrijolSuperAlimento;
