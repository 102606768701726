import { useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Btn.module.scss';

const Btn = ({ link, text, type, external, align, colorBlack }) => {
    const rippleInitialState = {
        isVisible: false,
        x: 0,
        y: 0,
    };
    const [isRipple, setRippleProps] = useReducer(
        (state, payload) => ({ ...state, ...payload }),
        rippleInitialState
    );
    const navigate = useNavigate();
    const clase = `${styles.btn} ${type && styles['btn--shadow']} ${
        colorBlack && styles['btn--black']
    } text-uppercase d-inline-block position-relative overflow-hidden fw-bold`;
    const removeRipple = () => {
        setRippleProps(rippleInitialState);
        external ? window.open(link) : navigate(link);
    };
    const ripple = (
        <span
            className={`${styles.btnRipple} position-absolute pe-none rounded-circle bg-white`}
            style={{ top: `${isRipple.y}px`, left: `${isRipple.x}px` }}
            onAnimationEnd={removeRipple}
        ></span>
    );
    const rippleEffect = e => {
        e.preventDefault();
        const x = e.clientX;
        const y = e.clientY;
        const buttonTop = Math.round(e.target.getBoundingClientRect().top);
        const buttonLeft = Math.round(e.target.getBoundingClientRect().left);
        setRippleProps({
            isVisible: true,
            x: x - buttonLeft,
            y: y - buttonTop,
        });
    };

    return (
        <div className={`${align ? `text-${align}` : ''}mb-1`}>
            {external ? (
                <a href={link} className={clase} onClick={rippleEffect}>
                    {text}
                    {isRipple.isVisible && ripple}
                </a>
            ) : (
                <Link to={link} className={clase} onClick={rippleEffect}>
                    {text}
                    {isRipple.isVisible && ripple}
                </Link>
            )}
        </div>
    );
};

Btn.defaultProps = {
    type: null,
    externall: null,
    align: null,
    colorBlack: false,
};

Btn.propTypes = {
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default Btn;
