import { Row, Col } from 'react-bootstrap';
import { MiToqueIsadoralogo } from '../../assets/images';
import styles from './BackGroundImageHero.module.scss';

const ContentHeroMiToqueIsadora = () => {
    const { heroMiToqueIsadoraImg, heroMiToqueIsadoraCopyContainer } = styles;
    return (
        <>
            <Row>
                <Col className='text-center'>
                    <img
                        src={MiToqueIsadoralogo}
                        className={`${heroMiToqueIsadoraImg} mb-3 mb-lg-5`}
                        alt='Mi Toque Isadora'
                    />
                    <div
                        className={`${heroMiToqueIsadoraCopyContainer} mx-auto`}
                    >
                        <p className='text-center mb-2'>
                            Prácticos consejos que gente como tú nos comparte
                            para darle un toquecito a sus frijoles Isadora.
                        </p>
                        <p className='text-center mb-2'>
                            ¿Cuál es tu secreto? Comparte con nosotros el toque
                            especial que le das a tus platillos.
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ContentHeroMiToqueIsadora;
