import { useRef, useState, useContext } from 'react';
import { globalContext } from '../../Hooks/GlobalState';
import Modal from 'react-modal';
import './styles.scss';
const customStyles = {
    content: {
        top: '0%',
        left: '0%',
        right: '0%',
        bottom: '0%',
        backgroundColor: 'rgba(0,0,0,.8)',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('body');

function SearchModal() {
    const {
        state: { searchModalActive },
        dispatch,
    } = useContext(globalContext);
    const [searchString, setSearchString] = useState('');
    const searchInput = useRef(null);
    const afterOpenModal = () => searchInput.current.focus();
    const closeModal = e => {
        dispatch({ searchModalActive: !searchModalActive });
        document.documentElement.removeAttribute('style');
    };
    const search = () => {
        if (searchString === '') return;
        window.location = '/resultados-de-busqueda/' + searchString;
    };
    const checkEnter = key => key.keyCode === 13 && search();

    return (
        <div>
            <Modal
                isOpen={searchModalActive}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <div className='modal_search_input_container'>
                    <input
                        ref={searchInput}
                        value={searchString}
                        onKeyUp={e => checkEnter(e)}
                        onChange={e => setSearchString(e.target.value)}
                        className='search_input'
                        type={'text'}
                    />
                </div>
                <button className='close_modal' onClick={closeModal}>
                    &times;
                </button>
            </Modal>
        </div>
    );
}

export default SearchModal;
