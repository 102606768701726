import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

const FilterNews = ({categoryId = 'all', fecha = 'all', texto = ''}) => {
    const navigate = useNavigate();

    const [values, setValues] = useState({
        search: '',
        category: 'all',
        date: 'all',
    });

   

    const [data, setCategories] = useState([]);

    const BASE_URL = process.env.REACT_APP_API_BASE_URL_NEWS;
    const endpoint = '/api/news/get-categories';


    const getCategories = async () => {
        const res = await fetch(
            BASE_URL+endpoint,
            {
                method: 'GET',
            }
        );
        const data = await res.json();
       
        setCategories(data);
    }

    useEffect(() => {
        getCategories();
    }, []);


    /*ESTO*/
    useEffect(() => {
        setValues(prevValues => ({
            ...prevValues,
            category: categoryId,
            date: fecha,
            search: (texto != 1)?texto:'',
        }));
    }, [categoryId, fecha, texto]);
    /*ESTO*/





    const handleChange = (field, value) => {
        setValues(prevValues => ({
            ...prevValues,
            [field]: value,
        }));

    };



    const yearInicial = 2022;
    const mesInicial = 0;
    let yearActual = new Date().getFullYear();
    let mesActual = new Date().getMonth();
    let yearContador = yearInicial;
    let mesContador = mesInicial;
    let cadOptions = '';
    let ketOpcionMes = 0;


    const monthsOfYear = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const monthsOfYearEs = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];




    const monthsOptions = [];

    for (let year = yearActual; year >= yearInicial; year--) {
        
        let mescambia = 11;

        if(year == yearActual){
            mescambia = mesActual;
        }

        for (let mes = mescambia ; mes >= mesInicial; mes--) {
            const optionValue = `${monthsOfYearEs[mes].toLowerCase()}-${year}`;
            const optionText = `${monthsOfYearEs[mes]} ${year}`;

            monthsOptions.push(
                <option key={optionValue} value={optionValue}>
                    {optionText}
                </option>
            );
        }

        /*for (let mes = mescambia ; mes >= mesInicial; mes--) {
            const optionValue = `${monthsOfYear[mes].toLowerCase()}-${year}`;
            const optionText = `${monthsOfYear[mes]} ${year}`;

            monthsOptions.push(
                <option key={optionValue} value={optionValue}>
                    {optionText}
                </option>
            );
        }*/
    }

   




    const makeSearch = e => {
        e.preventDefault();
        const { search, category, date } = values;

        let cad = '';

        if(search == ''){
            cad = '1';
        }
        else{
            cad = search;
        }


        navigate(`/noticias/resultados/${category}/${date}/${cad}`);
    };

    return (
        <div className='alx-cont-filter'>
            <form id='search-news-form' action='' onSubmit={e => makeSearch(e)}>
            
            <div className='row'>
            <div className='col-xs-12 col-md-4 col-lg-12'>



            <div className='row'>
                <div className='col-xs-12'>
                    <div className='alx-color-naranja alx-bold alx-etq-form'>
                        Buscar:
                    </div>
                </div>
                <div className='col-xs-12 alx-display-block alx-sig-input-form'>
                    <input
                        id='search-news-field'
                        type='text'
                        placeholder='Buscar noticias' 
                        value={values.search} 
                        onChange={e => handleChange('search', e.target.value)} 
                    />
                    <button id='search-news-button' className='circle-btn-orange'>
                        <FontAwesomeIcon icon={faSearch} className='fa-lg' />
                    </button>
                </div>
            </div>

            </div>
            <div className='col-6 col-md-4 col-lg-12'>



            <div className='row'>
                <div className='col-xs-12'>
                    <div className='alx-color-naranja alx-bold alx-etq-form'>
                        Categoría:
                    </div>
                </div>
                <div className='col-xs-12 alx-display-block alx-sig-input-form'>
                    <select className='select-news-field' id='category-news-field' 
                    value={values.category} 
                    onChange={e => {handleChange('category', e.target.value);

                    setTimeout(function() {
                      document.getElementById("search-news-button").click(); 
                    }, 100);

                    
                      
                    }} 
                    
                    >
                   


                    
                        <option key="all" value="all">Todas</option>
                         {Array.isArray(data.categories) ? (
                            data.categories.map(category => (
                                <option key={category.id} value={category.slug} >
                                    {category.nombre}
                                </option>
                            ))
                        ) : (
                           
                            <option key="error" value="error">Error loading categories</option>
                        )}
                       
                    </select>
                </div>
            </div>

            </div>
            <div className='col-6 col-md-4 col-lg-12'>

            <div className='row'>
                <div className='col-xs-12'>
                    <div className='alx-color-naranja alx-bold  alx-etq-form'>
                        Fecha:
                    </div>
                </div>
                <div className='col-xs-12 alx-display-block alx-sig-input-form'>
                    <select className='select-news-field' id='date-news-field' 
                    value={values.date}  
                    onChange={e => {
                        handleChange('date', e.target.value);
                        setTimeout(function() {
                          document.getElementById("search-news-button").click(); 
                        }, 100);

                    }} 
                    

                    


                    >
                        <option key="date-all" value="all">Todas</option>
                        {monthsOptions}
                    </select>
                </div>
            </div>

            </div>
            </div>

            </form>
        </div>
    );
};

export default FilterNews;
