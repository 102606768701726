import { useEffect, useState } from 'react';

const useMediaQuery = breakpoint => {
    const getMediaQuery = () => {
        const size =
            breakpoint === 'sm'
                ? 576
                : breakpoint === 'md'
                ? 768
                : breakpoint === 'lg'
                ? 992
                : breakpoint === 'xl'
                ? 1200
                : 1400;
        return window.matchMedia(`(min-width: ${size}px)`).matches;
    };
    const [match, setIsMatch] = useState(getMediaQuery);

    useEffect(() => {
        window.addEventListener('resize', setIsMatch.bind(null, getMediaQuery));
    }, []);
    return match;
};

export default useMediaQuery;
