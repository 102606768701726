import { Component } from 'react';
import './styles.css';
import './animate.css';
import { BackGroundImageHero } from '../../components';
import {
    florFrijolesIsadora,
    FSAB2Lineas2,
    lineaPunteada,
    sal1,
    sal2,
    sal3,
    sal4,
    frijo1,
    frijo2,
    frijo3,
    fle1,
    fle2,
    fle3,
    fle4,
    FSAB3Bowl,
    fuente,
    rayo,
    aguacate,
    casa,
    flecha5SA,
    FSAB5Cebollas,
    FSAB5Platillo,
    FSAB5Bowl1,
    mex,
    mapa,
} from '../../assets/media/index';
import 'animate.css/animate.min.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';

class FrijolSuperAlimento extends Component {

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        let img = document.getElementById('imagenDeFuenteBeans');

        if(img){
            let bounding = img.getBoundingClientRect();
            if (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                img.style.top = "0";
            } else {
            }
        }
    }

    render() {
        return (
            <>
                <BackGroundImageHero />

                <section id='nuestro'>
                    <div className='container tac'>
                        <img
                            src={FSAB2Lineas2}
                            className='img-fluid'
                            width='150'
                        />
                        <h3>
                            NUESTRO <br /> FRIJOL
                        </h3>
                        <p className='robotoReg'>
                            En Isadora utilizamos frijol de la más alta calidad
                            y cultivado en campos mexicanos. Nuestras
                            presentaciones incluyen tres diferentes variedades:
                        </p>
                        <img
                            src={lineaPunteada}
                            className='img-fluid'
                            width='10'
                        />
                    </div>

                    <div className='container tac'>
                        <div className='row'>
                            <div className='col-md-4 col-frijo'>
                                <AnimationOnScroll animateOnce animateIn='animate__animated animate__backInDown'>
                                    <img src={frijo1} className='img-fluid' />
                                    <h2>FRIJOL BAYO</h2>
                                    <p className='robotoReg'>
                                        Es un frijol grande con forma romboide y
                                        aplanada, de color beige acremado. Tiene
                                        una particular textura suave y un sabor
                                        agradable ideal para guarnición.
                                    </p>
                                </AnimationOnScroll>
                            </div>

                            <div className='col-md-4 col-frijo'>
                                <AnimationOnScroll animateOnce animateIn='animate__backInUp'>
                                    <img src={frijo2} className='img-fluid' />
                                    <h2>FRIJOL PERUANO</h2>
                                    <p className='robotoReg'>
                                        Es un frijol grande, de formal oval y
                                        muy abultado de color amarillo pálido.
                                        Su textura cremosa particular lo hace
                                        ideal para preparar diversos platillos.
                                    </p>
                                </AnimationOnScroll>
                            </div>
                            <div className='col-md-4 col-frijo'>
                                <AnimationOnScroll animateOnce animateIn='animate__backInDown'>
                                    <img src={frijo3} className='img-fluid' />
                                    <h2>FRIJOL NEGRO</h2>
                                    <p className='robotoReg'>
                                        Es un frijol de tamaño mediano, y de
                                        color negro brillante. Posee un sabor
                                        distintivo que es preferido por chefs y
                                        conocedores de la cocina mexicana.
                                    </p>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>

                <section id='salud'>
                    <div className='container'>
                        <h2 className='line-height-normal'>SALUD Y BIENESTAR PARA TODA TU FAMILIA</h2>

                        <div className='row sal'>
                            <div className='col-md-3'>
                                <div className='bowl dashed'>
                                    <AnimationOnScroll animateOnce animateIn='animate__backInLeft'>
                                        <img src={sal1} className='img-fluid' />
                                        <h4> Bajo índice glucémico</h4>
                                        <p className='robotoReg'>
                                            {' '}
                                            Isadora es ideal para personas
                                            diabéticas. Los componentes del
                                            frijol disminuyen la cantidad de
                                            glucosa y triglicéridos, manteniendo
                                            en equilibrio los niveles de azúcar
                                            en la sangre.
                                        </p>
                                    </AnimationOnScroll>
                                </div>

                                <div className='bowl bottom-bowl'>
                                    <AnimationOnScroll animateOnce animateIn='animate__backInLeft'>
                                        <img src={sal2} className='img-fluid' />
                                        <h4> Antioxidantes</h4>
                                        <p className='robotoReg'>
                                            {' '}
                                            Los frijoles negros Isadora
                                            contienen un alto contenido de
                                            antioxidantes que ayudan a prevenir
                                            radicales libres que influyen en el
                                            envejecimiento prematuro de las
                                            células.
                                        </p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                            <div className='col-md-6 rela'>
                                <AnimationOnScroll animateOnce animateIn='animate__backInLeft'>
                                    <img
                                        src={fle1}
                                        className='img-fluid fle1'
                                    />
                                </AnimationOnScroll>
                                <AnimationOnScroll animateOnce animateIn='animate__backInRight'>
                                    <img
                                        src={fle3}
                                        className='img-fluid fle2 fle2-top'
                                    />
                                </AnimationOnScroll>
                                <img
                                    src={FSAB3Bowl}
                                    className='img-fluid plato topla animated fadeInUp custom-max-width'
                                />
                                <AnimationOnScroll animateOnce animateIn='animate__backInLeft'>
                                    <img
                                        src={fle2}
                                        className='img-fluid fle1 fle1-bottom'
                                    />
                                </AnimationOnScroll>
                                <AnimationOnScroll animateOnce animateIn='animate__backInRight'>
                                    <img
                                        src={fle4}
                                        className='img-fluid fle2 fle2-bottom'
                                    />
                                </AnimationOnScroll>
                            </div>
                            <div className='col-md-3'>
                                <div className='bowl dashed'>
                                    <AnimationOnScroll animateOnce animateIn='animate__backInRight'>
                                        <img src={sal3} className='img-fluid' />
                                        <h4> Sin <br/> colesterol</h4>
                                        <p className='robotoReg smaller-max-width'>
                                            Los frijoles Isadora no contienen
                                            colesterol, lo que ayuda a reducir
                                            la presión arterial y la
                                            probabilidad de sufrir enfermedades
                                            cardiacas.
                                        </p>
                                    </AnimationOnScroll>
                                </div>

                                <div className='bowl bottom-bowl'>
                                    <AnimationOnScroll  animateOnce animateIn='animate__backInRight'>
                                        <img src={sal4} className='img-fluid w-75' />
                                        <h4> Fibra <br /> dietética</h4>
                                        <p className='robotoReg'>
                                            {' '}
                                            Isadora es tu aliado. Gracias a su
                                            alto contenido en fibra y agua te
                                            sentirás satisfecho con mayor
                                            rapidez y por más tiempo, así
                                            reduces calorías en tu dieta diaria.
                                        </p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id='fuente'>
                    <center id="beansFuente">
                        {/*<AnimationOnScroll animateOnce animateIn='animate__backInDown'>*/}
                            <img
                                src={fuente}
                                className='img-fluid fuente-top'
                                id='imagenDeFuenteBeans'
                            />
                        {/*</AnimationOnScroll>*/}
                    </center>
                    <div className='container'>
                        <div className='fuente-datos'>
                            <AnimationOnScroll animateOnce animateIn='animate__backInDown d-md-flex'>
                                <img src={rayo} className='img-fluid h-100' />
                                <div>
                                    <h2 className='fw-bold'>Fuente de energía</h2>
                                    <p className='robotoReg'>
                                        Los frijoles son carbohidratos con un
                                        alto contenido en fibra. Consumirlos de
                                        manera constante le dará a tu cuerpo la
                                        energía necesaria para realizar sus
                                        actividades diarias.
                                    </p>
                                </div>
                            </AnimationOnScroll>
                        </div>

                        <div className='fuente-datos'>
                            <AnimationOnScroll animateOnce animateIn='animate__backInDown d-md-flex'>
                                <img src={aguacate} className='img-fluid h-100' />
                                <div>
                                    <h2 className='fw-bold' >Sin conservadores</h2>
                                    <p className='robotoReg'>
                                        Los frijoles Isadora no contienen
                                        conservadores ya que utilizamos empaques
                                        diseñados con la más alta tecnología
                                        aptos para ser esterilizados. De esta
                                        manera nos aseguramos de hacer llegar a
                                        tu mesa un producto con textura y sabor
                                        inigualables.
                                    </p>
                                </div>
                            </AnimationOnScroll>
                        </div>

                        <div className='fuente-datos'>
                            <AnimationOnScroll animateOnce animateIn='animate__backInDown d-md-flex'>
                                <img src={casa} className='img-fluid h-100' />
                                <div>
                                    <h2 className='fw-bold'>Perfectos para toda ocasión</h2>
                                    <p className='robotoReg'>
                                        Por su delicioso sabor y altos atributos
                                        nutrimentales, los frijoles están
                                        presentes en todos los hogares
                                        mexicanos. Pueden ser parte de la comida
                                        diaria o reunir a las familias en
                                        fiestas y celebraciones.
                                    </p>
                                </div>
                            </AnimationOnScroll>
                        </div>

                        <div className='row sixtillo'>
                            <div className='col-md-8 tillo' id='frij-70-varied'>
                                <img
                                    src={flecha5SA}
                                    className='img-fluid tillo-fle hide-on-mobile'
                                    id='arrow5sa'
                                />
                                <AnimationOnScroll animateOnce animateIn='animate__backInUp'>
                                    <img
                                        src={FSAB5Cebollas}
                                        className='img-fluid hide-on-mobile'
                                        width='180'
                                    />
                                </AnimationOnScroll>
                                <AnimationOnScroll animateOnce animateIn='animate__backInUp'>
                                    <img
                                        src={FSAB5Platillo}
                                        className='img-fluid'
                                        width='470'
                                    />
                                </AnimationOnScroll>
                            </div>

                            <div className='col-md-4 tillo-text'>
                                <p className='tillop robotoReg'>
                                    Los frijoles son la leguminosa más
                                    importante para el consumo 
                                    <br />
                                    humano en el mundo*.
                                    <br />
                                    <strong>
                                        ¡Y existen cerca de 70 variedades!
                                    </strong>
                                </p>
                                <p className='tilloas'>
                                    *Según datos de la Organización de las
                                    Naciones Unidas para la Alimentación y la
                                    Agricultura (FAO).
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id='mexico'>
                    <div className='container'>
                        <AnimationOnScroll animateOnce animateIn='animate__backInRight'>
                            <img src={FSAB5Bowl1} className='img-fluid plafoot' />
                        </AnimationOnScroll>
                        <h2>ALIMENTO PARA TODO MÉXICO</h2>
                        <div className='row'>
                            <div className='col-md-5'>
                                <h3>A TRAVÉS DEL TIEMPO</h3>
                                <p>
                                    Desde la época prehispánica, el frijol ha
                                    formado parte esencial de la dieta de los
                                    mexicanos. Actualmente, es la leguminosa más
                                    consumida en el país debido a su
                                    versatilidad y la gran cantidad de
                                    nutrientes que aporta.
                                </p>
                                <center>
                                    <img
                                        src={lineaPunteada}
                                        className='img-fluid linn'
                                        width='10'
                                    />
                                </center>
                                <p>
                                    <b className='customBlack'>
                                        Los principales estados productores de
                                        frijol son:
                                    </b>
                                </p>
                            </div>
                            <div className='col-md-7'>
                                <AnimationOnScroll animateOnce animateIn='animate__backInRight'>
                                    <img src={mex} className='img-fluid mex mt-130px' />
                                </AnimationOnScroll>
                            </div>
                            <div className='col-md-10'>
                                <AnimationOnScroll animateOnce animateIn='animate__backInLeft'>
                                    <center>
                                        <img src={mapa} className='img-fluid' />
                                    </center>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default FrijolSuperAlimento;
