import { Container, Row, Col } from 'react-bootstrap';
import { Btn } from '../';
import {
    BannerUnSuperAlimentoFrijoles,
    BannerUnSuperAlimentoArrow,
} from '../../assets/images/';
import './styles.scss';
const BannerUnSuperAlimento = () => {
    return (
        <section className='super-alimento-banner py-lg-5'>
            <Container fluid='lg'>
                <Row className='justify-content-between align-items-center'>
                    <Col md={5}>
                        <h3 className='super-alimento-banner__headline mb-0'>
                            Frijol, un súper{' '}
                            <span className='d-block text-uppercase'>
                                alimento
                            </span>
                        </h3>
                        <p className='my-4 super-alimento-banner__description'>
                            No sólo son deliciosos, los frijoles también son
                            increíblemente nutritivos. Descubre los beneficios
                            que el frijol aporta a tu cuerpo para mantenerte
                            saludable.
                        </p>
                        <Btn
                            text='Conoce más'
                            link='/frijol-un-super-alimento'
                            colorBlack
                        />
                    </Col>
                    <Col md={7} className='pt-4 pt-md-0 d-md-flex'>
                        <div className='super-alimento-banner__img-desc d-none d-md-block position-relative'>
                            <img
                                src={BannerUnSuperAlimentoArrow}
                                alt='Flecha - Indicador'
                            />
                            <p className='pt-4'>
                                Una taza de frijoles aporta el doble de proteína
                                que un huevo y prácticamente nada de grasa.
                            </p>
                        </div>
                        <div className='super-alimento-banner__img-container mx-auto'>
                            <img
                                src={BannerUnSuperAlimentoFrijoles}
                                alt='Frijoles enteros Isadora'
                                className='img-fluid'
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default BannerUnSuperAlimento;
