import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../Layout';
import { routes } from './routes';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' exact element={<Layout />}>
                    {routes.map((route, i) => {
                        const { component, path } = route;
                        const index = !path ? true : false;
                        return (
                            <Route
                                index={index}
                                path={path}
                                key={i}
                                element={component}
                            />
                        );
                    })}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
