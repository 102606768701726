import { Link } from 'react-router-dom';
import { Recipes1 } from '../../assets/images';
import { useEffect } from 'react';
import { useFetch } from '../../utilities/hooks';
import Preloader from '../../components/Preloader';
import './styles.scss';

const RelatedNews = ({ categoryId, newsId }) => {
    /*const BASE_URL = process.env.REACT_APP_API_BASE_URL;*/

    

    const BASE_URL = process.env.REACT_APP_API_BASE_URL_NEWS;
    const { data } = useFetch(
        `${BASE_URL}/api/news/related/${newsId}/${categoryId}`
    );

    useEffect(() => {
        if (data) {
            /*console.log(data);*/
        }
    }, [data]);

    return (
        <div className='row'>
            {data?.news ? (
                data?.news.length == 0 ? (
                    <></>
                ) : (
                    <>
                        <div className='row'>
                            <div className="col-xs-12">
                                <h4 className='text-orangeIs font-gotham-bold mb-4'>
                                    RELATED NEWS
                                </h4>
                            </div>
                        </div>
                                
                        {data.news.map((noticia, i) => {
                            return (

                                <div className="col-xs-12 col-md-4" 
                                    key={noticia.id}
                                > 
                                    <Link to={'/noticias/' + noticia.slug} 
                                        onClick={() => window.scrollTo(0, 0)}
                                        className='alx-receta-min'>
                                        <img
                                             src={
                                                    BASE_URL +
                                                    '/uploads/noticias/' +
                                                    noticia.imagen_mobile
                                                }
                                            alt=""
                                            className='w-auto img-fluid'
                                        />
                                        <div className='alx-color-cafe'>
                                            {noticia.titulo}
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </>
                )
            ) : (
                <Preloader />
            )}
        </div>
    );
};

export default RelatedNews;
