import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';
import styles from './NavbarToggle.module.scss';

const NavBarToggle = ({ active, eventHandler, type }) => {
    const { navToggle, navToggleActive, navToggleLine } = styles;
    return (
        <Navbar.Toggle
            aria-controls='basic-navbar-nav'
            className={`${navToggle}${
                active ? ` ${navToggleActive}` : ''
            } d-flex d-lg-none flex-column justify-content-between border-0 shadow-none position-relative p-0`}
            onClick={eventHandler}
            style={{ marginRight: type && '.75rem' }}
        >
            {[...Array(3)].map((_, index) => (
                <span
                    key={index}
                    className={`${navToggleLine} ${
                        styles[`navToggleLine--${index + 1}`]
                    } d-block`}
                ></span>
            ))}
        </Navbar.Toggle>
    );
};

NavBarToggle.defaultProps = {
    type: null,
};

NavBarToggle.propTypes = {
    active: PropTypes.bool.isRequired,
    eventHandler: PropTypes.func.isRequired,
};

export default NavBarToggle;
