import { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Btn } from '../../';

class ContactUsBanner extends Component {
    render() {
        return (
            <section id='contact-home' className='overflow-hidden'>
                <Container className='animated fadeInRight'>
                    <h3>
                        <span className='h3-normal-fw'>¿TIENES DUDAS</span> O
                        COMENTARIOS?
                    </h3>
                    <p>
                        ¡Queremos escucharte! Ponte en contacto con nosotros y
                        déjanos saber tu opinión sobre nuestros productos o
                        nuestro sitio web.
                    </p>
                    <Btn text='Contáctanos' link='contacto' />
                </Container>
            </section>
        );
    }
}

export default ContactUsBanner;
