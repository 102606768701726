import React from 'react';
import './style.css';

class FAQ extends React.Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="row justify-content-center faq-row">
            <div className="col-md-12 col-lg-7 col-xl-7">
              <h1 className='faq-title'>PREGUNTAS FRECUENTES</h1>
              <section className='question'>
                <h3 className='question-title'>¿QUÉ ES UN POUCH?</h3>
                <p>Se le llama así al empaque tipo bolsa diseñado con la más alta tecnología, hecho con una laminación apta para esterilizarse, que garantiza la inocuidad y conserva mejor el sabor de nuestros frijoles.</p>
              </section>
              <section className='question'>
                <h3 className='question-title'>¿LOS FRIJOLES ISADORA TIENEN CONSERVADORES?</h3>
                <p>Gracias a nuestro proceso de empaque al vacío, no es necesario añadir ningún tipo de conservador o aditivo.</p>
              </section>
              <section className='question'>
                <h3 className='question-title'>¿ES CIERTO QUE LOS FRIJOLES ISADORA NO CONTIENEN COLESTEROL?</h3>
                <p>¡Es cierto! Los frijoles Isadora no contienen colesterol ni ácidos grasos trans.</p>
              </section>
              <section className='question'>
                <h3 className='question-title'>¿PUEDO COMER FRIJOLES ISADORA SI QUIERO CUIDAR LA LÍNEA?</h3>
                <p>¡Claro! Isadora tiene un alto contenido en fibra, lo que te ayudará a sentirte satisfecho por más tiempo. Además, contamos con frijoles refritos bajos en grasa.</p>
              </section>
              <section className='question'>
                <h3 className='question-title'>¿CUÁLES SON LOS BENEFICIOS DE CONSUMIR FRIJOLES ISADORA?</h3>
                <p>Los frijoles Isadora saben como hechos en casa y además son nutritivos y saludables.</p>
              </section>
              <section className='question'>
                <h3 className='question-title'>¿CUÁNTO TIEMPO DURAN LOS FRIJOLES ISADORA UNA VEZ ABIERTOS?</h3>
                <p>Al igual que unos frijoles hechos en casa, una vez abiertos, los frijoles Isadora deben permanecer en refrigeración y se recomienda consumirlos durante los siguientes 7 días.</p>
              </section>
              <section className='question'>
                <h3 className='question-title'>¿PUEDO PREPARAR COMIDAS COMPLETAS CON FRIJOLES ISADORA?</h3>
                <p>¡Por supuesto! Con Isadora puedes echar a volar tu imaginación y preparar platillos deliciosos.</p>
              </section>
              <section className='question'>
                <h3 className='question-title'>¿CÓMO PUEDO CONTACTAR A ISADORA?</h3>
                <p>
                  Queremos conocer tus dudas y comentarios. <a href="../contacto" /* Reemplazar */ className='text-reset text-decoration-none'>¡Escríbenos aquí!</a>
                </p>
              </section>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default FAQ;