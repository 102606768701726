import { Container } from 'react-bootstrap';
import {
    BannerUnSuperAlimento,
    BackGroundImageHero,
    FacebookPosts,
} from '../../components';
import './styles.scss';
const MiToqueIsadora = () => {
    return (
        <>
            <BackGroundImageHero />
            <section>
                <Container>
                    <div className='string_contents'>
                        <h2 className='miToqueIsadoraTitle'>
                            HAZLO ESPECIAL, <strong>PONLE TU TOQUE</strong>
                        </h2>
                        <div className='text'>
                            <p className='robotoReg'>
                                Sabemos que te encanta sorprender a tus seres
                                queridos con platillos que demuestren tu amor y
                                lleven tu huella. ¿Cuál es tu secreto para darle
                                una sazón especial a tus frijoles Isadora?
                            </p>
                            <div className='little_space'></div>
                            <p className='robotoReg'>
                                Compártelo y descubre lo que otras personas
                                hacen para transformar cada plato en algo único.
                            </p>
                        </div>
                    </div>
                </Container>
                <FacebookPosts />
            </section>
            <BannerUnSuperAlimento />
        </>
    );
};

export default MiToqueIsadora;
