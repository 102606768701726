import React from 'react';
import {Link} from 'react-router-dom';
import './style.css';
import { lineaVerdeValle } from '../../assets/images';

class Contact extends React.Component {

    constructor(props){
        super(props);
        this.state= {
            ads: true,
            name: '',
            errors: false,
            success: false,
            btnDissabled: false,
            messageError: false,
            backEndMsgError: false,
            backEndMsgErrorIp: false,
        }
    }

    async sendContact()
    {
        this.setState({ errors: false });
        this.setState({ backEndMsgError: false });
        this.setState({ backEndMsgErrorIp: false });

        if(!document.getElementById("privacyCheck").checked){
            this.setState({errors: true});
            return;
        }

        let phoneRegex = /^[0-9\b]+$/;
        const emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

        if(!emailRegex.test(document.getElementById("inputEmail").value)){
            await this.setState({messageError: true});
            document.getElementById("messageError").innerHTML = "Por favor, ingrese un email válido.";
            return;
        }

        if(!phoneRegex.test(document.getElementById("inputTel").value))
        {
            await this.setState({messageError: true});
            document.getElementById("messageError").innerHTML = "Por favor, ingrese un teléfono válido.";
            return;
        }

        await this.setState({name: document.getElementById("inputName").value});

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: document.getElementById("inputName").value,
                last_name: document.getElementById("inputSurname").value,
                email: document.getElementById("inputEmail").value,
                phone: document.getElementById("inputTel").value,
                address: document.getElementById("inputAdress").value,
                city: document.getElementById("inputCity").value,
                country: document.getElementById("inputCountry").value,
                reason: document.getElementById("inputMotive").value,
                message: document.getElementById("inputTextareaComents").value,
                privacy: document.getElementById("privacyCheck").checked,
                accepts_ads: document.getElementById("newsletterCheck").checked,
				googleresponsetoken: document.getElementById("google-response-token").value,
                lang: "es",
            }),
        }

        fetch(process.env.REACT_APP_API_ENDPOINT_URI+'api/contact', options)
            .then((response) => response.json())
            .then(
                (result) => {
                    //console.log(result);
                    if(result.Status == "Not a valid email provider")
                    {
                        this.setState({ backEndMsgError: true });
                        return;
                    }
                    if(result.Status == "Contact Not Saved, IP already used")
                    {
                        this.setState({ backEndMsgErrorIp: true });
                        return;
                        //mensaje de ip ya usada
                    }

                    if(result.Status !== "Validation failed"){
                        this.setState({btnDissabled: true});
                        this.setState({errors: false});
                        this.setState({success: true});
                        return;
                    }
                    else{
                        this.setState({errors: true});
                        return;
                    }
                },
                (error) => {
                    console.log(error)
                    this.setState({btnDissabled: true});
                    this.setState({errors: false});
                    this.setState({success: true});
                    return;
                });
    }

    render() {
        return (
            <>
                <div className="container my-5">
                    <div className="row justify-content-center">
                       
                        <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 p-4">
                            <h1 className="contactTitle robotoBold">CONTÁCTENOS</h1>
							<input type="hidden" name="google-response-token" id="google-response-token" />
                            <p className="text-center mb-5 robotoReg">¿Tienes alguna duda, comentario o te gustaría saber más sobre los productos
                                Isadora? Ponte en contacto con
                                nosotros y te atenderemos tan pronto como sea posible.</p>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-12 linea-vv-container'>
                        <a className='alx-link-tel' href="tel:+8008470088">
                        <img className='line-verde-valle-logo' src={lineaVerdeValle} alt="" />
                        </a>


                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Contact;
