import { routes } from '../../views/Router/routes';

const data = {
    navigation: [
        {
            title: 'Menú',
            links: routes.filter(route => route.menu || route.footer),
        },
        {
            title: 'Productos',
            links: [
                { text: 'Frijoles Refritos Bayos', path: 'productos' },
                {
                    text: 'Frijoles Refritos Peruanos',
                    path: 'producto/frijoles-refritos-peruanos',
                },
                {
                    text: 'Frijoles Refritos Negros',
                    path: 'producto/frijoles-refritos-negros',
                },
                {
                    text: 'Frijoles Refritos Bayos sin Grasa',
                    path: 'producto/frijoles-refritos-bayos-sin-grasa',
                },
                {
                    text: 'Frijoles Refritos con Chorizo',
                    path: 'producto/frijoles-refritos-con-chorizo',
                },
                {
                    text: 'Frijoles Refritos con Chicharrón',
                    path: 'producto/frijoles-refritos-con-chicharron',
                },
                {
                    text: 'Frijoles Enteros Claros',
                    path: 'producto/frijoles-enteros-claros',
                },
                {
                    text: 'Frijoles Enteros Negros',
                    path: 'producto/frijoles-enteros-negros',
                },
                {
                    text: 'Arroz Blanco con Elote',
                    path: 'producto/arroz-blanco-con-elote',
                },
                {
                    text: 'Arroz Blanco Sazonado',
                    path: 'producto/arroz-blanco-sazonado',
                },
                {
                    text: 'Arroz a la Mexicana',
                    path: '/producto/arroz-a-la-mexicana',
                },
            ],
        },
    ],
    redesSociales: [
        'Isadora es una marca de Productos Verde Valle',
        'Síguenos en:',
    ],
    copyright: routes.filter(route => route.copyright),
};

export default data;
